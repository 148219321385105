import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { BsTrash, BsPencilFill } from "react-icons/bs";
import { IoIosAddCircleOutline } from "react-icons/io";
import AddBanner from "../../components/banner/AddBanner";
import ListTables from "../../components/tables/ListTables";
import { removeLocalStorageAndNavigate } from "../../utils/helperFunctions";
import {
  useGetBannersQuery,
  useDeleteBannerMutation,
} from "../../redux/api/bannerApi";
import style from "../../components/banner/banner.module.css";

const Banner = () => {
  const navigate = useNavigate();
  const [addbanner, setAddbanner] = useState(false);
  const [banner, setBanner] = useState({ _id: "" });
  const [selectedList, setSelectedLIst] = useState("banners");

  const [deleteBanner, { error }] = useDeleteBannerMutation();

  if (error) {
    if (error.status === 401) {
      removeLocalStorageAndNavigate(navigate);
    } else {
      alert(error.data.error);
    }
  }

  const dataRemap = (data) => {
    console.log(data);

    return data.map(filter);
  };

  const filter = (obj) => {
    return {
      _id: obj._id,
      banner_type: obj.banner_type,
      placement: obj.bannerFor,
      icon: (
        <img src={obj.banner_url} style={{ height: "100px", width: "100px" }} />
      ),
      banner_action_url: obj.banner_action_url,
      active: obj.active,
    };
  };

  const deleteHandler = async (list) => {
    if (window.confirm("Do you want to delete the banner ?")) {
      await deleteBanner({
        banner_id: list._id,
      });
    }
  };

  //for edit you can use
  const editBanner = (list) => {
    console.log(list, "list");
    setAddbanner(true);
    setBanner({
      _id: list._id,
      banner_type: list.banner_type,
      banner_url: list.icon.props.src,
      banner_action_url: list.banner_action_url,
    });
  };

  const selectedListHandler = (listType) => {
    setSelectedLIst(listType);

    if (listType === "banners") {
    } else {
    }
  };

  return (
    <div className="root-top-margin">
      {!addbanner && (
        <div>
          <div
            className={style.addIcon}
            // style={{ display: "flex", justifyContent: "space-between" }}
          >
            {/* <div style={{ fontSize: "14px", display: "flex", gap: "25px" }}>
              <div
                className={` ${
                  selectedList === "banners"
                    ? "btn btn-primary"
                    : "btn btn-info"
                }`}
                onClick={() => selectedListHandler("banners")}
              >
                Sliders
              </div>
              <div
                className={` ${
                  selectedList === "placements"
                    ? "btn btn-primary"
                    : "btn btn-info"
                }`}
                onClick={() => selectedListHandler("placements")}
              >
                Placements
              </div>
            </div> */}
            <button
              className="btn btn-success"
              onClick={() => {
                setAddbanner(true);
              }}
            >
              Add Banner/slider
            </button>
          </div>

          <ListTables
            search={true}
            tableTitle="Lists of Banners"
            tableHeders={[
              "Banner Type",
              "Placment",
              "Banner Image",
              "action url",
              "active",
              "Actions",
            ]}
            paramAdditional={{ country_id: "KW" }}
            useGetListHook={useGetBannersQuery}
            dataRemap={dataRemap}
            actions={[
              { icon: <BsTrash />, handler: deleteHandler },
              { icon: <BsPencilFill />, handler: editBanner },
            ]}
            // errordata={
            //   error &&
            //   (error.data.error ? error.data.error : 'some thing went wrong')
            // }
          />
        </div>
      )}

      {addbanner && (
        <AddBanner
          setAddbanner={setAddbanner}
          banner={banner}
          setBanner={setBanner}
        />
      )}
    </div>
  );
};

export default Banner;
