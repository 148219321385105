import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Post from '../components/users/Post'
import PasswordLinkChange from '../pages/PasswordLinkChange/PasswordLinkChange'
const UserLayout = ({ component }) => {
  return (
    <Container fluid>
      <Row>
        {component === 'userpost' && (
          <Col>
            <Post />
          </Col>
        )}

        {component === 'emailpasswordchange' && (
          <Col>
            <PasswordLinkChange />
          </Col>
        )}
      </Row>
    </Container>
  )
}

export default UserLayout
