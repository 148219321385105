import React, { useState, useRef } from 'react'
import style from '../../../pages/region/region.module.css'
import {
  useAddAreaMutation,
  useGetCountriesSelectedQuery,
  useGetDistrictsQuery,
  useEditAreaMutation,
} from '../../../redux/api/regionApi'

const AddArea = ({ setAddArea, area, setArea, country_id }) => {
  const { data, error, isSuccess } = useGetCountriesSelectedQuery({})

  const [nameEn, setNameEn] = useState(area._id ? area.name_en : '')
  const [nameAr, setNameAr] = useState(area._id ? area.name_ar : '')
  const [countryID, setCountryID] = useState(country_id ? country_id : '0')
  const [disrictID, setDisrictID] = useState(area._id ? area.id : '0')
  const [loading, setLoading] = useState(false)
  const [addArea] = useAddAreaMutation()
  const [editArea] = useEditAreaMutation()

  const { data: disData, error: disError } = useGetDistrictsQuery({
    country_id: countryID,
    searchText: '',
  })

  if (error) {
    alert(error.data.error)
  }

  if (disError) {
    alert(disError.data.error)
  }

  const onSaveHandler = async () => {
    if (!nameEn) {
      alert('distrct name EN is required')
      return
    }
    if (!nameAr) {
      alert('district name AR is required')
      return
    }

    if (countryID === '0') {
      alert('country is required')
      return
    }

    if (disrictID === '0') {
      alert('district is required')
      return
    }

    setLoading(true)

    const res = await addArea({
      area_en: nameEn,
      area_ar: nameAr,
      country_id: countryID,
      district_id: disrictID,
    })

    if (res.error) {
      if (res.error.status === 400) {
        alert(res.error.data.error)
      }
    }
    if (res.data) {
      setAddArea(false)
      setArea({ _id: '' })
      setCountryID('0')
      setDisrictID('0')
    }
    setLoading(false)
  }

  const editHandler = async () => {
    console.log('edit')
    if (!nameEn) {
      alert('distrct name EN is required')
      return
    }
    if (!nameAr) {
      alert('district name AR is required')
      return
    }

    if (countryID === '0') {
      alert('country is required')
      return
    }

    if (disrictID === '0') {
      alert('district is required')
      return
    }

    console.log(nameEn, area._id, area.id)
    setLoading(true)
    const res = await editArea({
      area_id: area._id,
      area_en: nameEn,
      area_ar: nameAr,
      district_id: area.id,
    })

    if (res.error) {
      if (res.error.status === 400) {
        alert(res.error.data.error)
      }
    }

    if (res.data) {
      setNameAr('')
      setNameEn('')
      setArea({ _id: '' })
      setCountryID('0')
      setAddArea(false)
    }
    setLoading(false)
  }
  return (
    <div>
      <div className={style.headerWrapper}>Add Area</div>
      <div className={style.adWrapper}>
        {loading && (
          <div>
            <span className='loader-spiner'></span>
            <span className='text-primary'>...adding area</span>
          </div>
        )}

        <div className={style.nameTextsWrapper}>
          <div className={style.textLable}>Name(EN)</div>
          <div>
            <input
              value={nameEn}
              type='text'
              className={style.nameTexts}
              onChange={(e) => {
                setNameEn(e.target.value)
              }}
              disabled={loading ? true : false}
            />
          </div>
        </div>
        <div className={style.nameTextsWrapper}>
          <div className={style.textLable}>Name(AR)</div>
          <div>
            <input
              type='text'
              value={nameAr}
              className={style.nameTexts}
              onChange={(e) => {
                setNameAr(e.target.value)
              }}
              disabled={loading ? true : false}
            />
          </div>

          <div>
            {isSuccess && data.length > 0 && !area._id && (
              <select
                onChange={(e) => setCountryID(e.target.value)}
                className={`${style.selectTexts} mt-3 mb-3`}
                defaultValue={countryID}
              >
                <option value='0'>Select Country</option>
                {data.map((list, i) => {
                  return (
                    <option value={list._id} key={list._id + i}>
                      {list.country_english}
                    </option>
                  )
                })}
              </select>
            )}
          </div>
          <div>
            {disData && disData.length > 0 && (
              <select
                onChange={(e) => setDisrictID(e.target.value)}
                className={`${style.selectTexts} mt-3 mb-3`}
                defaultValue={disrictID}
              >
                <option value='0'>Select District</option>
                {disData.map((list, i) => {
                  return (
                    <option value={list._id} key={list._id + i}>
                      {list.district_en}
                    </option>
                  )
                })}
              </select>
            )}
          </div>
        </div>

        <div className={style.buttonWrapper}>
          <button
            className='btn btn-success'
            onClick={() => {
              if (area._id) {
                editHandler()
              } else {
                onSaveHandler()
              }
            }}
          >
            Save
          </button>
          <button
            className='btn btn-danger'
            onClick={() => {
              setAddArea(false)
              setArea({ _id: '' })
            }}
          >
            Back
          </button>
        </div>
      </div>
    </div>
  )
}

export default AddArea
