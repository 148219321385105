import React from 'react'
import { useNavigate } from 'react-router-dom'
import { removeLocalStorageAndNavigate } from '../../utils/helperFunctions'
import { useGetCommentsQuery } from '../../redux/api/postApi'
import image from '../../images/user2.jpg'
import style from './post.module.css'

const LatestComments = ({ post_id }) => {
  const navigate = useNavigate()
  const { data, error, isLoading, isSuccess } = useGetCommentsQuery({
    post_id: post_id,
  })

  if (error) {
    if (error.status === 401) {
      removeLocalStorageAndNavigate(navigate)
    } else {
      alert(error.data.error)
    }
  }
  return (
    <div>
      {isSuccess && (
        <h4 className='mb-4'>Latest top {data.comments.length} comments</h4>
      )}
      {isSuccess &&
        data.comments.map((comment) => {
          return (
            <div key={comment._id} className={style.commentswrapper}>
              <img
                src={comment.commenting_user_id.photo}
                alt={comment.commenting_user_id.name}
                onError={(e) => {
                  e.target.onError = null
                  e.target.src = image
                }}
                className={style.commentUserimage}
              />
              <div className={style.commentind}>{comment.comments}</div>
            </div>
          )
        })}
    </div>
  )
}

export default LatestComments
