import React, { useEffect, useState } from "react";
import style from "./classifieds.module.css";
import {
  useGetClassifiedQuery,
  useAllowDisllowClassifiedMutation,
} from "../../redux/api/classifiedApi";
const ViewClassifiedPost = ({ classifiedPost, setClassifiedPost }) => {
  const [allowed, setAllowed] = useState();
  const { data, isSuccess, isLoading, isError } = useGetClassifiedQuery({
    classified_id: classifiedPost._id,
  });

  const [allowDisllowClassified] = useAllowDisllowClassifiedMutation();

  useEffect(() => {
    if (isSuccess) {
      console.log("active", data.active);
      setAllowed(data.active);
    }
  }, [data, isSuccess]);

  const changeAllowHandler = async (isAllowed) => {
    const res = await allowDisllowClassified({
      classified_id: classifiedPost._id,
      active: isAllowed,
    });

    if (res.error) {
      if (res.error.status === 400) {
        alert(res.error.data.error);
      }
    }
    if (res.data) {
      setAllowed(isAllowed);
    }
  };

  // if (isSuccess) {
  //   console.log(data)
  // }
  return (
    <div>
      {isLoading && (
        <div className="flex-item-center">
          <span className="loader-spiner"></span>
        </div>
      )}
      <button
        className="btn btn-danger mb-2"
        onClick={() => {
          setClassifiedPost("");
        }}
      >
        Back
      </button>
      {isSuccess && (
        <div className={style.ViewPostWrapper}>
          <div className={style.shortinfoWrapper}>
            <div className="mb-1">
              <b className="mr-1">User Posted:</b>
              <span>{data.user_id.name}</span>
            </div>

            <div className="mb-1">
              <b className="mr-1">Category:</b>
              <span>{data.category_id.category_en}</span>
            </div>
          </div>

          <div className="flex-item-center p-1">
            <img
              className={style.imgThumb}
              src={data.image_url}
              alt={data.title + data.category_id.category_en}
              onError={(e) => {
                e.target.onError = null;
                e.target.src =
                  "https://rasel-media-node.s3.me-south-1.amazonaws.com/files/classifieds/defaultthumbnail.png";
              }}
            />
          </div>

          <div className="mt-2">Classfied Details:-</div>
          <hr />
          <table class="table">
            <tbody>
              <tr>
                <td>allowed</td>
                <td>
                  {console.log("down", allowed)}
                  <select
                    value={allowed}
                    onChange={(e) => changeAllowHandler(e.target.value)}
                  >
                    <option value={true}>allowed</option>
                    <option value={false}>disallowed</option>
                  </select>
                </td>
              </tr>
              {Object.entries(data).map((key) => {
                if (
                  key[0] !== "media" &&
                  key[0] !== "updatedAt" &&
                  key[0] !== "__v" &&
                  key[0] !== "id" &&
                  key[0] !== "image_url" &&
                  key[0] !== "classifiedType"
                )
                  if (key[1] && key[1]._id) {
                    if (key[0] !== "user_id" && key[0] !== "currencyDetails") {
                      return (
                        <tr>
                          <td>{key[0].split("_id")[0]}:</td>
                          {Object.values(key[1]).map((value, index) => {
                            if (index === 1) return <td>{value}</td>;
                          })}
                        </tr>
                      );
                    } else {
                      if (key[0] !== "user_id") {
                        return (
                          <tr>
                            <td>{key[0].split("_id")[0]}:</td>
                            <td>{key[1].currency_symbol}</td>
                          </tr>
                        );
                      } else {
                        return (
                          <tr>
                            <td>{key[0].split("_id")[0]}:</td>
                            <td>{key[1].name}</td>
                          </tr>
                        );
                      }
                    }
                  } else {
                    if (key[0] !== "createdAt") {
                      return (
                        <tr>
                          <td>
                            {key[0].split("_id")[0]
                              ? key[0].split("_id")[0]
                              : "ID"}
                            :
                          </td>
                          <td>{key[1] && key[1].toString()}</td>
                        </tr>
                      );
                    } else {
                      return (
                        <tr>
                          <td>
                            {key[0].split("_id")[0]
                              ? key[0].split("_id")[0]
                              : "ID"}
                            :
                          </td>
                          <td>
                            {key[1] &&
                              new Date(key[1]).toLocaleDateString("en-US", {
                                year: "numeric",
                                month: "short",
                                day: "numeric",
                              })}{" "}
                          </td>
                        </tr>
                      );
                    }
                  }
              })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default ViewClassifiedPost;
