import React from "react";

const DropDwonDistricts = ({
  useDropDownHook,
  queryparams,
  setID,
  dropDowntitle,
}) => {
  const { data, error, isSuccess } = useDropDownHook({
    ...queryparams,
    searchText: "",
  });

  console.log(data);
  return (
    <div>
      {isSuccess && data.length > 0 && (
        <select
          onChange={(e) => setID(e.target.value)}
          className={`mt-3 mb-3`}
        >
          <option value="0">{dropDowntitle}</option>
          {data.map((list, i) => {
            return (
              <option value={list._id} key={list._id + i}>
                {list.district_en}
              </option>
            );
          })}
        </select>
      )}
    </div>
  );
};

export default DropDwonDistricts;
