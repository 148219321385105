import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const supportApi = createApi({
  reducerPath: 'supportApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_backendurl,
    prepareHeaders: (headers) => {
      if (typeof Storage !== 'undefined') {
        const user = JSON.parse(localStorage.getItem('RaselListing'))
        if (user?.token) headers.set('Authorization', `Bearer ${user?.token}`)
      }
      return headers
    },
  }),
  tagTypes: ['Settings tickets'],
  endpoints: (builder) => ({
    getSetting: builder.query({
      query: ({}) => ({
        url: `api/admin/app-settings`,
      }),
      providesTags: ['Settings'],
    }),
    editSettings: builder.mutation({
      query(data) {
        return {
          url: `api/admin/edit-app-settings`,
          method: 'POST',
          body: data,
        }
      },
      invalidatesTags: ['Settings'],
    }),
    changePassword: builder.mutation({
      query(data) {
        return {
          url: `api/update-password`,
          method: 'POST',
          body: data,
        }
      },
    }),

    updateContactForm: builder.mutation({
      query(data) {
        return {
          url: `api/support/update-contact-form`,
          method: 'POST',
          body: data,
        }
      },
    }),
    getContactForms: builder.query({
      query: ({ page, maxCountInaPage }) => ({
        url: `api/support/get-contact-forms?page=${page}&itemsPerPage=${maxCountInaPage}`,
      }),
    }),
    GetTickets: builder.query({
      query: ({ page, maxCountInaPage }) => ({
        url: `api/support/get-all-tickets?page=${page}&itemsPerPage=${maxCountInaPage}`,
      }),
      providesTags: ['tickets'],
    }),
    assignTicket: builder.mutation({
      query(data) {
        return {
          url: `api/support/assign-ticket`,
          method: 'POST',
          body: data,
        }
      },
    }),
    updateTicket: builder.mutation({
      query(data) {
        return {
          url: `api/support/update-ticket`,
          method: 'POST',
          body: data,
        }
      },
      invalidatesTags: ['tickets'],
    }),
    getATicket: builder.query({
      query: ({ ticket_id }) => ({
        url: `api/support/get-ticket-messages?ticket_id=${ticket_id}&itemsPerPage=10&page=1`,
      }),
      providesTags: ['tickets'],
    }),
  }),
})

export const {
  useEditSettingsMutation,
  useGetSettingQuery,
  useChangePasswordMutation,
  useGetContactFormsQuery,
  useUpdateContactFormMutation,
  useGetTicketsQuery,
  useAssignTicketMutation,
  useGetATicketQuery,
  useUpdateTicketMutation,
} = supportApi
