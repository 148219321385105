import React, { useEffect, useState } from "react";

const Error = ({ error }) => {
  const [showError, setShowError] = useState(true);
  useEffect(() => {
    const errorMsg = setTimeout(() => {
      setShowError(false);
    }, 3000);
    return () => clearTimeout(errorMsg);
  }, [error]);

  return (
    <div>
      {showError && (
        <div
          className="color-danger"
          style={{
            background: "white",
            justifyContent: "flex-start",
            display: "flex",
          }}
        >
          <div
            className="p-1"
            style={{
              background: "#FD9800",
            }}
          >
            {error}
          </div>
        </div>
      )}
    </div>
  );
};

export default Error;
