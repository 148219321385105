import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { BsTrash, BsPencilFill } from 'react-icons/bs'
import ListTables from '../../components/tables/ListTables'
import { removeLocalStorageAndNavigate } from '../../utils/helperFunctions'
import {
  useGetAreaQuery,
  useUpdateSelectCountryMutation,
  useGetCountriesSelectedQuery,
  useGetDistrictsQuery,
} from '../../redux/api/regionApi'

import style from './region.module.css'

import AddArea from '../../components/countries/area/AddArea'
import DropDwonDistricts from './DropDwonDistricts'

const Area = () => {
  const navigate = useNavigate()
  const [area, setArea] = useState({ _id: '' })
  const [addArea, setAddArea] = useState(false)
  const [countryID, setCountryID] = useState('KW')
  const [disctrictID, setDistrictID] = useState('')

  const [updateSelectCountry, { error }] = useUpdateSelectCountryMutation()

  const {
    data,
    error: errorCountires,
    isSuccess,
  } = useGetCountriesSelectedQuery({})

  if (errorCountires) {
    if (errorCountires.status === 401) {
      removeLocalStorageAndNavigate(navigate)
    } else {
      alert(errorCountires.data.error)
    }
  }

  if (error) {
    if (error.status === 401) {
      removeLocalStorageAndNavigate(navigate)
    }
  }

  const dataRemap = (data) => {
    return data.map(filter)
  }

  const filter = (obj) => {
    return {
      _id: obj._id,
      id: obj.district_id,
      name_en: obj.area_en,
      name_ar: obj.area_ar,
      country: obj.country_id,
    }
  }

  const editArea = async (area) => {
    setArea(area)
    setCountryID(area.country)
    setAddArea(true)
    //await updateSelectCountry(data);
  }

  return (
    <div className='root-top-margin'>
      {!addArea && (
        <div>
          <div className='flex-item-space-btw'>
            <div className={`${style.flexArea} mr-1`}>
              {isSuccess && data.length > 0 && (
                <select
                  onChange={(e) => setCountryID(e.target.value)}
                  className={`mt-3 mb-3 mr-1`}
                  defaultValue={countryID}
                >
                  <option value='0'>Select Country</option>
                  {data.map((list, i) => {
                    return (
                      <option value={list._id} key={list._id + i}>
                        {list.country_english}
                      </option>
                    )
                  })}
                </select>
              )}
              {isSuccess && (
                <DropDwonDistricts
                  useDropDownHook={useGetDistrictsQuery}
                  queryparams={{ country_id: countryID }}
                  setID={setDistrictID}
                  dropDowntitle='Select District'
                />
              )}
            </div>
            <div className={style.addIcon}>
              <button
                className='btn btn-success'
                onClick={() => {
                  setAddArea(true)
                }}
              >
                Add Area
              </button>
            </div>
          </div>
          <ListTables
            search={true}
            tableTitle='Areas'
            tableHeders={['id', 'Name EN', 'Name AR', 'Country', 'Actions']}
            useGetListHook={useGetAreaQuery}
            paramAdditional={{
              country_id: countryID,
              district_id: disctrictID,
            }}
            dataRemap={dataRemap}
            actions={[
              {
                icon: <BsPencilFill size={20} />,
                handler: editArea,
              },
            ]}
            errordata={
              error &&
              (error.data.error ? error.data.error : 'some thing went wrong')
            }
          />
        </div>
      )}

      {addArea && (
        <AddArea
          setAddArea={setAddArea}
          area={area}
          setArea={setArea}
          country_id={countryID}
        />
      )}
    </div>
  )
}

export default Area
