import React from 'react'
import style from '../../pages/DashBoard/Dashboard.module.css'

const CountCard = ({ Text, Icon, backgroundC, Count, loading }) => {
  return (
    <div className={style.countWrapper}>
      <div className={style.iconBox} style={{ ...backgroundC }}>
        {Icon}
      </div>
      <div className={style.countValueText}>
        <div>{Text}</div>
        {!loading && <div className={style.count}>{Count}</div>}
        {loading && (
          <div className={style.count}>
            <span className='loader-spiner'></span>
          </div>
        )}
      </div>
    </div>
  )
}

export default CountCard
