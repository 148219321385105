import React, { useEffect, useState } from 'react'

const Message = ({ message, success, error }) => {
  const [showMessage, setShowMessage] = useState(message)

  useEffect(() => {
    const errorMsg = setTimeout(() => {
      setShowMessage('')
    }, 3000)
    setShowMessage(message)
    return () => clearTimeout(errorMsg)
  }, [message])

  return (
    <div>
      {showMessage && (
        <>
          {error && (
            <div
              className='color-danger'
              style={{
                background: 'white',
                justifyContent: 'flex-start',
                display: 'flex',
              }}
            >
              <div
                className='p-1'
                style={{
                  background: '#FD9800',
                }}
              >
                {message}
              </div>
            </div>
          )}
          {success && (
            <div>
              <div
                style={{
                  background: '#28a745',
                  color: 'white',
                  padding: '10px',
                }}
              >
                {message}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default Message
