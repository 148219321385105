import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { BsEyeFill } from 'react-icons/bs'
import ListTables from '../../components/tables/ListTables'
import { removeLocalStorageAndNavigate } from '../../utils/helperFunctions'
import { useGetPostsQuery } from '../../redux/api/postApi'
import Post from '../../components/post/Post'

const Posts = ({ user_id }) => {
  const navigate = useNavigate()
  const [viewPost, setViewPost] = useState('')
  const [page, setPage] = useState(1)

  // if (error) {
  //   if (error.status === 401) {
  //     removeLocalStorageAndNavigate(navigate)
  //   }
  // }

  const dataRemap = (data) => {
    return data.posts.map(filter)
  }

  const filter = (obj) => {
    return {
      _id: obj._id,
      userName: obj.user_id.name,
      title: obj.title,
      description: obj.description,
      Multiple_files: obj.media.length > 0 ? 'YES' : 'NO',
      Post_date: new Date(obj.createdAt).toLocaleDateString('de-DE'),
      Status: obj.active,
    }
  }

  const viewPostHandler = async (post) => {
    setViewPost(post._id)
  }

  return (
    <div className='root-top-margin'>
      {!viewPost && (
        <ListTables
          search={true}
          tableTitle='Post List Table'
          tableHeders={[
            'User Posted',
            'Title',
            'Description',
            'Multiple files',
            'Post date',
            'Status',
            'Action',
          ]}
          paramAdditional={{
            user_id: user_id,
          }}
          dataRemap={dataRemap}
          useGetListHook={useGetPostsQuery}
          actions={[{ icon: <BsEyeFill />, handler: viewPostHandler }]}
          setPage={setPage}
          page={page}
          maxCountInaPage={10}
          // errordata={
          //   error &&
          //   (error.data.error ? error.data.error : 'some thing went wrong')
          // }
        />
      )}
      {viewPost && <Post ID={viewPost} setViewPost={setViewPost} />}
    </div>
  )
}

export default Posts
