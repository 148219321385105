import React, { useState } from 'react'
import ListTables from '../tables/ListTables'
import { useGetReportedUsersQuery } from '../../redux/api/userApi'

const ReportedUsers = () => {
  const [page, setPage] = useState(1)

  const dataRemap = (data) => {
    return data.reported.map(filter)
  }

  const filter = (obj) => {
    return {
      _id: obj._id,
      id: obj._id,
      ReportedBy: obj.reported_user_id.name,
      ReportedUser: obj.user_id.name,
    }
  }

  return (
    <div className='root-top-margin'>
      <ListTables
        tableTitle='User List Table'
        tableHeders={['Reported ID', 'Reported By', 'Reported User']}
        dataRemap={dataRemap}
        useGetListHook={useGetReportedUsersQuery}
        setPage={setPage}
        page={page}
      />
    </div>
  )
}

export default ReportedUsers
