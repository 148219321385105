import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const uploadApi = createApi({
  reducerPath: 'uploadApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_backendurl,
    prepareHeaders: (headers) => {
      if (typeof Storage !== 'undefined') {
        const user = JSON.parse(localStorage.getItem('RaselListing'))
        if (user?.token) headers.set('Authorization', `Bearer ${user?.token}`)
      }
      return headers
    },
  }),

  endpoints: (builder) => ({
    getPreSignedUrl: builder.mutation({
      query(data) {
        return {
          url: `api/uploads/getPreSignedURLForUploadObject`,
          method: 'POST',
          body: data,
        }
      },
    }),
  }),
})

export const { useGetPreSignedUrlMutation } = uploadApi
