import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import style from './Dashboard.module.css'
import { PiUsersThreeFill } from 'react-icons/pi'
import { BsFillSignpostSplitFill } from 'react-icons/bs'
import { AiOutlineHistory } from 'react-icons/ai'
import { AiFillLike } from 'react-icons/ai'
import { IoShareSocial } from 'react-icons/io5'
import { FaComment } from 'react-icons/fa'
import { removeLocalStorageAndNavigate } from '../../utils/helperFunctions'
import CountCard from '../../components/dashboard/CountCard'
import { useTotalCountsMutation } from '../../redux/api/userApi'

const Dashboard = () => {
  const navigate = useNavigate()
  const [count, setCounts] = useState({})
  const [loading, setLoading] = useState({})
  const [totalCounts] = useTotalCountsMutation()

  const countHandler = async (dateType) => {
    setLoading(true)
    const res = await totalCounts({
      dateType: dateType,
    })

    if (res.error) {
      if (res.error.status === 400) {
        alert(res.error.data.error)
        return
      }

      if (res.error) {
        if (res.error.status === 401) {
          removeLocalStorageAndNavigate(navigate)
        }
      }
    }
    if (res.data) {
      console.log(res.data)
      setCounts(res.data)
    }
    setLoading(false)
  }

  useEffect(() => {
    countHandler('ALLTIME')
  }, [])

  return (
    <div className='p-4'>
      <h2>Dashboard</h2>
      <div className='root-top-margin'>
        <div className={style.dashboardFilterWrapper}>
          <select
            className={style.slectWrapper}
            onChange={(e) => countHandler(e.target.value)}
          >
            <option value='ALLTIME'>All time</option>
            <option value='TODAY'>Today</option>
            <option value='WEEK'>This Week</option>
            <option value='MONTH'>This Month</option>
            <option value='YEAR'> This Year</option>
            <option value='LASTYEAR'> Last Year</option>
          </select>
        </div>

        <div className={style.dashboardResultWrapper}>
          <CountCard
            Icon={<PiUsersThreeFill size={40} color='white' />}
            backgroundC={{ backgroundColor: '#FC950C' }}
            Text={'Total Users'}
            Count={count.user || 0}
            loading={loading}
          />
          <CountCard
            Icon={<BsFillSignpostSplitFill size={40} color='white' />}
            backgroundC={{ backgroundColor: '#4CA750' }}
            Text={'Total User Posts'}
            Count={count.posts || 0}
            loading={loading}
          />
          <CountCard
            Icon={<AiOutlineHistory size={40} color='white' />}
            backgroundC={{ backgroundColor: '#EA4542' }}
            Text={'Total Story'}
            Count={count.stories || 0}
            loading={loading}
          />
          <CountCard
            Icon={<BsFillSignpostSplitFill size={40} color='white' />}
            backgroundC={{ backgroundColor: '#11B8CC' }}
            Text={'Total Classfieds'}
            Count={count.classifiedies || 0}
            loading={loading}
          />
          <CountCard
            Icon={<AiFillLike size={40} color='white' />}
            backgroundC={{ backgroundColor: '#54AD58' }}
            Text={'Total Likes'}
            Count={count.likeposts || 0}
            loading={loading}
          />
          <CountCard
            Icon={<IoShareSocial size={40} color='white' />}
            backgroundC={{ backgroundColor: '#11B8CC' }}
            Text={'Total Shares'}
            Count={count.sharedposts || 0}
            loading={loading}
          />

          <CountCard
            Icon={<FaComment size={40} color='white' />}
            backgroundC={{ backgroundColor: '#FD9C17' }}
            Text={'Total Comments'}
            Count={count.comments || 0}
            loading={loading}
          />
        </div>
      </div>
    </div>
  )
}

export default Dashboard
