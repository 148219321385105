import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { removeLocalStorageAndNavigate } from '../../utils/helperFunctions'
import style from './setting.module.css'
import Message from '../../components/Message'
import {
  useEditSettingsMutation,
  useGetSettingQuery,
} from '../../redux/api/supportApi'
const Settings = () => {
  const navigate = useNavigate()
  const { data, error, isLoading } = useGetSettingQuery({})

  const [settingID, setSettingID] = useState(data ? data._id : '')
  const [site, setSite] = useState(data ? data.site : '')
  const [phone, setPhone] = useState(data ? data.phone : '')
  const [email, setEmail] = useState(data ? data.email : '')
  const [facebook, setFacebook] = useState(data ? data.facebook : '')
  const [twitter, setTwitter] = useState(data ? data.twitter : '')
  const [address, setAddress] = useState(data ? data.address : '')
  const [success, setSucess] = useState(false)
  const [errors, setError] = useState('')
  const [editSettings, { isLoading: isUpdateLoading }] =
    useEditSettingsMutation()

  useEffect(() => {
    setSettingID(data ? data._id : '')
    setSite(data ? data.site : '')
    setPhone(data ? data.phone : '')
    setEmail(data ? data.email : '')
    setFacebook(data ? data.facebook : '')
    setTwitter(data ? data.twitter : '')
    setAddress(data ? data.address : '')
  }, [data])

  if (error) {
    if (error.status === 401) removeLocalStorageAndNavigate(navigate)
    if (error.status !== 401) alert(error)
  }

  const updateHandler = async () => {
    console.log(site)
    if (!site) {
      setError('site is required')
      return
    }
    if (!phone) {
      setError('phone is required')
      return
    }
    if (!email) {
      setError('email is required')
      return
    }

    const res = await editSettings({
      settingID,
      site,
      phone,
      email,
      facebook,
      twitter,
      address,
    })

    if (res.error) {
      if (res.error.status === 400) {
        alert(res.error.data.error)
      }
    }
    if (res.data) {
      setSucess(true)
    }
  }
  return (
    <div className='root-top-margin'>
      <div className={style.boxWrapper}>
        {success && <Message message={'Update Success'} success />}
        {errors && (
          <Message
            message={errors ? errors : 'some thing went wrong'}
            error={true}
          />
        )}
        <h4>Settings</h4>
        <div className='m-1'>
          <div className={style.label}>Site</div>
          <input
            type='text'
            value={site}
            className={style.inputSetting}
            autoFocus
            onChange={(e) => {
              setSite(e.target.value)
            }}
          />
        </div>
        <div className='m-1'>
          <div className={style.label}>Phone</div>
          <input
            type='text'
            value={phone}
            className={style.inputSetting}
            autoFocus
            onChange={(e) => {
              setPhone(e.target.value)
            }}
          />
        </div>
        <div className='m-1'>
          <div className={style.label}>Email</div>
          <input
            type='text'
            value={email}
            className={style.inputSetting}
            autoFocus
            onChange={(e) => {
              setEmail(e.target.value)
            }}
          />
        </div>
        <div className='m-1'>
          <div className={style.label}>Facebook</div>
          <input
            type='text'
            value={facebook}
            className={style.inputSetting}
            autoFocus
            onChange={(e) => {
              setFacebook(e.target.value)
            }}
          />
        </div>
        <div className='m-1'>
          <div className={style.label}>Twitter</div>
          <input
            type='text'
            value={twitter}
            className={style.inputSetting}
            autoFocus
            onChange={(e) => {
              setTwitter(e.target.value)
            }}
          />
        </div>
        <div className='m-1'>
          <div className={style.label}>Address</div>
          <input
            type='text'
            value={address}
            className={style.inputSetting}
            autoFocus
            onChange={(e) => {
              setAddress(e.target.value)
            }}
          />
        </div>
        <div className={style.buttonwrapper}>
          <button className='btn btn-primary' onClick={updateHandler}>
            Update
          </button>
          <button
            className='btn btn-danger'
            onClick={() => window.history.back()}
          >
            Back
          </button>
          {isUpdateLoading && (
            <div>
              <span className='loader-spiner'></span>
              <span className='text-primary'>updating....</span>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Settings
