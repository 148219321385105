import React, { useState } from 'react'
import style from './post.module.css'
import ListTables from '../tables/ListTables'
import { useGetReportedPostQuery } from '../../redux/api/postApi'

const UserReportedOnAPost = ({ post_id }) => {
  const [page, setPage] = useState(1)

  const dataRemap = (data) => {
    return data.reported.map(filter)
  }

  const filter = (obj) => {
    return {
      _id: obj._id,
      ReportedUserID: obj.reported_user_id._id,
      userName: obj.reported_user_id.name,
      description: obj.report_description,
      date: new Date(obj.createdAt).toLocaleDateString('de-DE'),
    }
  }

  return (
    <div className={style.boxReportWrapper}>
      <ListTables
        tableTitle='Post Reported Table'
        tableHeders={[
          'Reported User Id',
          'Reported User Name',
          'Description',
          'date',
        ]}
        paramAdditional={{
          post_id: post_id,
        }}
        dataRemap={dataRemap}
        useGetListHook={useGetReportedPostQuery}
        setPage={setPage}
        page={page}
        maxCountInaPage={10}

        // errordata={
        //   error &&
        //   (error.data.error ? error.data.error : 'some thing went wrong')
        // }
      />
    </div>
  )
}

export default UserReportedOnAPost
