import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import AddClassifiedPostComponent from "../../components/addClassifiedPost/AddClassifiedPost";
import AddAutomotiveClassified from "../../components/addClassifiedPost/AddAutomotiveClassified";
const AddClassifiedPost = () => {
  const Query = new URLSearchParams(useLocation().search);
  useEffect(() => {
    if (!Query.get("id")) {
      alert("Classified ID is missing in the URL");
    }
    if (!Query.get("classified")) {
      alert("classified param is missing in the url");
    }
  }, [Query]);

  return (
    <>
      {Query.get("classified") === "automotive" && (
        <AddAutomotiveClassified
          classified_id={Query.get("id")}
          classifiedType={Query.get("classified")}
        />
      )}

      {Query.get("classified") !== "automotive" && (
        <AddClassifiedPostComponent />
      )}
    </>
  );
};

export default AddClassifiedPost;
