import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import AddValuesToDropDowns from "../../components/classifieds/AddValuesToDropDowns";
const AddDropDownsClassified = () => {
  const Query = new URLSearchParams(useLocation().search);

  useEffect(() => {
    if (!Query.get("id")) {
      alert("Classified ID is missing in the URL");
    }
    if (!Query.get("classified")) {
      alert("classified param is missing in the url");
    }
  }, [Query]);

  return (
    <AddValuesToDropDowns
      classifiedID={Query.get("id")}
      classifiedType={Query.get("classified")}
    />
  );
};

export default AddDropDownsClassified;
