export const ifUserInLocalStorageNavigate = async (navigate) => {
  if (typeof Storage !== 'undefined') {
    const user = JSON.parse(localStorage.getItem('RaselListing'))
    if (user)
      if (user.token) {
        navigate('/rm-admin/dashboard')
      }
  } else {
    alert('Update browser')
  }
}

export const removeLocalStorageAndNavigate = async (navigate) => {
  if (typeof Storage !== 'undefined') {
    localStorage.removeItem('RaselListing')
    navigate('/rm-admin')
  } else {
    alert('Update browser')
  }
}

export const setLocalStorage = async (data) => {
  if (typeof Storage !== 'undefined') {
    localStorage.setItem('RaselListing', JSON.stringify(data))
  } else {
    alert('Update browser')
  }
}

export const getLocalStorage = async () => {
  if (typeof Storage !== 'undefined') {
    const user = JSON.parse(localStorage.getItem('RaselListing'))
    return user
  } else {
    alert('Update browser')
  }
}
