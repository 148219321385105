import React, { useState, useEffect, useRef, useCallback } from "react";
import { useParams } from "react-router-dom";
import style from "../../components/post/post.module.css";
import { useGetPostQuery } from "../../redux/api/postApi";
import image from "../../images/user2.jpg";
import android from "../../images/android_link.png";
import apple from "../../images/itunes_link.png";
import Rasel from "../../images/Rasel-Logo1.png";
const Post = () => {
  const [canPlay, setCanPlay] = useState(true);
  const [videoElementReady, setVideoElementReady] = useState(false);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [hasPaused, setHasPaused] = useState(false);
  const [deviceType, setDeviceType] = useState("");

  const params = useParams();
  console.log(params);
  const { data, error, isLoading, isSuccess } = useGetPostQuery({
    post_id: params.id,
  });

  // const setRef = useCallback((node) => {
  //   videoRef.current = node;
  //   setVideoElementReady(!!node); // Set flag to true when node is assigned
  // }, []);

  useEffect(() => {
    const getDeviceType = () => {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;

      if (/android/i.test(userAgent)) {
        return "Android";
      }

      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
      }

      return "Other";
    };

    setDeviceType(getDeviceType());
  }, []);

  // useEffect(() => {
  //   if (videoElementReady) {
  //     console.log("play video");
  //     const videoElement = videoRef.current;
  //     const canvas = canvasRef.current;
  //     const ctx = canvas.getContext("2d");

  //     if (
  //       isSuccess &&
  //       data.media &&
  //       data.media.length > 0 &&
  //       data.media[0].type !== "image"
  //     ) {
  //       const captureSnapshot = () => {
  //         if (videoElement && canvas && ctx) {
  //           // Set canvas size to match video size
  //           canvas.width = videoElement.videoWidth;
  //           canvas.height = videoElement.videoHeight;

  //           // Draw the current video frame onto the canvas
  //           ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height);

  //           // Create an image from the canvas
  //           const dataURL = canvas.toDataURL("image/png");
  //           setPosterUrl(dataURL);
  //           setCanPlay(true); // Set poster URL to the snapshot
  //         }
  //       };

  //       const handleCanPlay = () => {
  //         // Seek to 1 second after the video can play
  //         videoElement.currentTime = 1;
  //       };

  //       const handleSeeked = () => {
  //         // Ensure the video has buffered enough after seeking
  //         captureSnapshot();
  //       };

  //       const handleError = () => {
  //         console.error("Video playback error.");
  //         alert("Error with video playback.");
  //       };

  //       videoElement.addEventListener("canplay", handleCanPlay);
  //       videoElement.addEventListener("seeked", handleSeeked);
  //       videoElement.addEventListener("error", handleError);

  //       return () => {
  //         videoElement.removeEventListener("canplay", handleCanPlay);
  //         videoElement.removeEventListener("seeked", handleSeeked);
  //         videoElement.removeEventListener("error", handleError);
  //       };
  //     }
  //   }
  // }, [isSuccess, data, videoElementReady]);

  return (
    <div>
      <div className={style.boxWrapper} style={{ width: "100%" }}>
        {isLoading && (
          <div className="flex-item-center">
            <span className="loader-spiner"></span>
          </div>
        )}
        <div
          className="display-flex"
          style={{
            gap: "1rem",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img src={Rasel} alt={"image"} className="rasel-logo" />
        </div>
        {isSuccess && (
          <div
            className="display-flex"
            style={{ gap: "1rem", alignItems: "center", marginTop: "5px" }}
          >
            <div className="flex-item-center">
              <img
                src={data.user_id.photo}
                alt={data.user_id.name}
                onError={(e) => {
                  e.target.onError = null;
                  e.target.src = image;
                }}
                className={style.imgRound}
              />
            </div>
            <div>
              <div className={style.dataTitle}>{data.title}</div>

              <div className="flex-item-space-btw">
                <div className={style.userpostinfo}>
                  <div className="pt-2 pb-2">
                    <span>Posted User:</span>
                    <span className="ml-2">{data.user_id.name}</span>
                  </div>

                  <div className="pt-2 pb-2">
                    <span>Created:</span>
                    <span>
                      {new Date(data.createdAt).toLocaleDateString("de-DE")},
                    </span>
                    <span>
                      {new Date(data.createdAt).toLocaleTimeString("en-US")}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <canvas ref={canvasRef} style={{ display: "none" }} />
        {isSuccess && (
          <div className="mt-2 flex-item-center">
            <div
              className={style.imgorvideodisplay}
              style={{ alignItems: "center" }}
            >
              {data.media.length > 0 && data.media[0].type === "image" && (
                <img
                  src={data.media[0].url}
                  alt={"image"}
                  style={{ width: "100%" }}
                />
              )}

              {data.media.length > 0 && data.media[0].type !== "image" && (
                <>
                  {!canPlay && <span className="loader-spiner"></span>}
                  <video
                    controls
                    className={`video-post-share ${
                      canPlay ? "display-video" : "display-not-video"
                    }`}
                    preload="metadata"
                    ref={videoRef}
                    crossOrigin="anonymous" // Required for cross-origin video
                    autoPlay={deviceType === "iOS" ? true : false}
                    // muted
                    // onTimeUpdate={(e) => {
                    //   console.log(e.target.currentTime);
                    //   if (!hasPaused && e.target.currentTime >= 0.1) {
                    //     e.target.pause();
                    //     e.target.muted = false;
                    //     setHasPaused(true);
                    //   }
                    // }}
                  >
                    <source src={data.media[0].url} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </>
              )}
              <div className="mt-1 mb-2">{data.description}</div>
              <hr></hr>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  cursor: "pointer",
                  flexWrap: "wrap",
                  marginBottom: "10px",
                }}
              >
                <a href="https://apps.apple.com/in/app/rasel-media/id6502615878">
                  <img
                    src={apple}
                    alt={"image"}
                    className="app-logo-icon"
                    target="_blank"
                  />
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.raselmedia">
                  <img
                    src={android}
                    target="_blank"
                    alt={"image"}
                    className="app-logo-icon"
                  />{" "}
                </a>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Post;
