import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { removeLocalStorageAndNavigate } from '../../utils/helperFunctions'
import { useChangePasswordMutation } from '../../redux/api/supportApi'
import style from './setting.module.css'
import Message from '../../components/Message'

const ChangePassword = () => {
  const navigate = useNavigate()
  const [success, setSucess] = useState(false)
  const [errors, setError] = useState('')
  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  const [changePassword, { isLoading: isUpdateLoading }] =
    useChangePasswordMutation()

  const updateHandler = async () => {
    if (!oldPassword) {
      setError('old password is required')
      return
    }
    if (!newPassword) {
      setError('new password is required')
      return
    }
    if (!confirmPassword) {
      setError('confim password is required')
      return
    }

    if (newPassword !== confirmPassword) {
      setError('Enterted new password not matching')
    }

    const res = await changePassword({
      oldPassword,
      newPassword,
    })

    if (res.error) {
      if (res.error.status !== 400) {
        alert(res.error.data.error)
      }
      if (res.error.status === 400) {
        alert(res.error.data.error)
      }
      if (res.error.status === 401) removeLocalStorageAndNavigate(navigate)
    }
    if (res.data) {
      setSucess(true)
    }
  }

  return (
    <div className='root-top-margin'>
      <div className={style.boxWrapper}>
        {success && <Message message={'Update password success'} success />}
        {errors && (
          <Message
            message={errors ? errors : 'some thing went wrong'}
            error={true}
          />
        )}

        <div className='m-1'>
          <div className={style.label}>Old Password</div>
          <input
            type='password'
            value={oldPassword}
            className={style.inputSetting}
            autoFocus
            onChange={(e) => {
              setOldPassword(e.target.value)
            }}
          />
        </div>
        <div className='m-1'>
          <div className={style.label}>New PassWord</div>
          <input
            type='password'
            value={newPassword}
            className={style.inputSetting}
            autoFocus
            onChange={(e) => {
              setNewPassword(e.target.value)
            }}
          />
        </div>

        <div className='m-1'>
          <div className={style.label}>Confrim New PassWord</div>
          <input
            type='password'
            value={confirmPassword}
            className={style.inputSetting}
            autoFocus
            onChange={(e) => {
              setConfirmPassword(e.target.value)
            }}
          />
        </div>
        <div className={style.buttonwrapper}>
          <button className='btn btn-primary' onClick={updateHandler}>
            Update
          </button>
          <button
            className='btn btn-danger'
            onClick={() => window.history.back()}
          >
            Back
          </button>
          {isUpdateLoading && (
            <div>
              <span className='loader-spiner'></span>
              <span className='text-primary'>updating....</span>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default ChangePassword
