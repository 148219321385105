import { createSlice } from '@reduxjs/toolkit'

const initialState = {}

export const user = createSlice({
  name: 'user',
  initialState,
  reducers: {
    addUser: (state, action) => {
      return action.payload
    },
  },
})

export const { addUser } = user.actions

export default user.reducer
