import { useLocation } from 'react-router-dom'
import React, { useState, useRef } from 'react'
import { useForgortChangePasswordMutation } from '../../redux/api/authApi'
import logo from '../../images/OrangeLogo-box.png'
import './forgotpassword.css'

const PasswordLinkChange = () => {
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [loading, setLoading] = useState(false)
  const [text, setText] = useState({ message: '', class: '' })
  const uploadMSG = useRef(null)
  const [forgortChangePassword] = useForgortChangePasswordMutation()
  const useQuery = () => {
    return new URLSearchParams(useLocation().search)
  }

  let query = useQuery()

  const resetLink = async (e) => {
    if (password !== confirmPassword) {
      alert('Password mismatch')
      return
    }
    setLoading(true)
    console.log(query.get('email'))
    try {
      const res = await forgortChangePassword({
        password: password,
        token: query.get('email'),
      })

      if (res.error) {
        alert(res.error.data.error)
        setLoading(false)
      }
      if (res.data) {
        setText({ message: 'password changed successfully' })
        setPassword('')
        setConfirmPassword('')
      }
      setLoading(false)
    } catch (err) {
      setLoading(false)
      alert(err.message)
      console.log(err)
    }
  }
  return (
    <div className='forgot-password-cointainer'>
      <div ref={uploadMSG} className='flex-center'></div>

      {text.message && (
        <div className='successMessageTextWrapper'>
          <div className='mb-2'>
            <img src={logo} className={`imag-logo`} alt='rasel logo' />
          </div>
          <div className={`${text.class} success-message-box`}>
            {text.message}
          </div>
          <div className='margin-top-1 flex-center'>
            <p className='or-login'> you can Login - in your mobile</p>
          </div>
        </div>
      )}
      {text.message === '' && (
        <div className='forgot-box'>
          <div className='forgot-form-wrapper'>
            <div className='mb-2'>
              <img src={logo} className={`imag-logo`} alt='rasel logo' />
            </div>

            <div className='input-label'>Password</div>
            <input
              type='password'
              className='input-email'
              placeholder='password'
              autoComplete='off'
              value={password}
              onChange={(e) => {
                setPassword(e.target.value)
              }}
            />

            <div className='input-label margin-top-1'>Confirm password</div>
            <input
              type='password'
              className='input-email'
              placeholder='password'
              autoComplete='off'
              value={confirmPassword}
              onChange={(e) => {
                setConfirmPassword(e.target.value)
              }}
            />
            <div className='button-wrapper'>
              <button className='button-reset-send' onClick={resetLink}>
                Reset Password
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default PasswordLinkChange
