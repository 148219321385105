import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const storiesApi = createApi({
  reducerPath: 'storiesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_backendurl,
    prepareHeaders: (headers) => {
      if (typeof Storage !== 'undefined') {
        const user = JSON.parse(localStorage.getItem('RaselListing'))
        if (user?.token) headers.set('Authorization', `Bearer ${user?.token}`)
      }
      return headers
    },
  }),
  tagTypes: ['Stories'],
  endpoints: (builder) => ({
    getStories: builder.query({
      query: ({ page, maxCountInaPage, searchText, user_id }) => ({
        url: `api/story/get-stories-by-admin?page=${page}&itemsPerPage=${maxCountInaPage}${
          searchText ? `&textSearch=${searchText}` : ''
        }${user_id ? `&user_id=${user_id}` : ''}`,
      }),
      providesTags: ['Stories'],
    }),
    getStory: builder.query({
      query: ({ story_id }) => ({
        url: `api/story/get-story-admin?story_id=${story_id}`,
      }),
    }),
  }),
})

export const { useGetStoriesQuery, useGetStoryQuery } = storiesApi
