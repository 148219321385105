import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { BsTrash, BsPencilFill } from 'react-icons/bs'

import { IoIosAddCircleOutline } from 'react-icons/io'
import style from './classifieds.module.css'
import AddClassified from '../../components/classifieds/AddClassified'
import ListTables from '../../components/tables/ListTables'
import { removeLocalStorageAndNavigate } from '../../utils/helperFunctions'
import {
  useGetClassifiedsCategoryQuery,
  useDeleteClassifiedCategoryMutation,
} from '../../redux/api/classifiedApi'
import SubCategories from '../classifieds/SubCategories'
const Classifieds = () => {
  const navigate = useNavigate()
  const [addclassified, setAddClassified] = useState(false)
  const [showSubCategories, setSubCategories] = useState(false)
  const [classified, setClassified] = useState({ _id: '' })
  const [addSubclassified, setAddSubClassified] = useState(false)

  const [deleteClassifiedCategory, { data, error }] =
    useDeleteClassifiedCategoryMutation()

  if (error) {
    if (error.status === 401) {
      removeLocalStorageAndNavigate(navigate)
    }
  }

  const dataRemap = (data) => {
    return data.map(filter)
  }

  const filter = (obj) => {
    return {
      _id: obj._id,
      name_en: obj.category_en,
      name_ar: obj.category_ar,
      icon: (
        <img
          src={obj.category_image}
          style={{ height: '100px', width: '100px' }}
        />
      ),
    }
  }

  const deleteHandler = async (list) => {
    if (window.confirm('Do you want to delete the classified category ?')) {
      await deleteClassifiedCategory({
        category_id: list._id,
      })
    }
  }

  const addHandler = async (list) => {
    setClassified(list)
    setSubCategories(true)
    setAddSubClassified(true)
  }

  const editClassified = (list) => {
    setAddClassified(true)
    setClassified({
      _id: list._id,
      category_en: list.name_en,
      category_ar: list.name_ar,
      image: list.icon.props.src,
    })
  }

  return (
    <div className='root-top-margin'>
      {!addclassified && !showSubCategories && (
        <div>
          <div className='flex-item-space-btw '>
            <div className={style.addIcon}>
              <button
                className='btn btn-success'
                onClick={() => {
                  setSubCategories(true)
                }}
              >
                Sub categories list
              </button>
            </div>
            <div className={style.addIcon}>
              <button
                className='btn btn-success'
                onClick={() => {
                  setAddClassified(true)
                }}
              >
                Add classified category
              </button>
            </div>
          </div>
          <ListTables
            search={true}
            tableTitle='Lists of classifieds'
            tableHeders={['Name EN', 'Name AR', 'Icon', 'Actions']}
            useGetListHook={useGetClassifiedsCategoryQuery}
            dataRemap={dataRemap}
            actions={[
              { icon: <BsTrash />, handler: deleteHandler },
              { icon: <BsPencilFill />, handler: editClassified },
              {
                icon: <IoIosAddCircleOutline size={20} />,
                handler: addHandler,
              },
            ]}
            errordata={
              error &&
              (error.data.error ? error.data.error : 'some thing went wrong')
            }
          />
        </div>
      )}

      {showSubCategories && (
        <SubCategories
          setSubCategories={setSubCategories}
          category={classified}
          setAddSubClassified={setAddSubClassified}
          addSubclassified={addSubclassified}
        />
      )}

      {addclassified && (
        <AddClassified
          setAddClassified={setAddClassified}
          classified={classified}
          setClassified={setClassified}
        />
      )}
    </div>
  )
}

export default Classifieds
