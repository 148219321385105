import React, { useState } from "react";
import styles from "./Form.module.css";
import {
  useGetDistrictsQuery,
  useGetCountriesSelectedQuery,
  useGetAreaDistrictsQuery,
} from "../../redux/api/regionApi";
import {
  useGetcategoriesDropDownQuery,
  useGetPcategoriesDropDownQuery,
} from "../../redux/api/classifiedApi";
const AddClassiedPostCommonFields = ({
  formData,
  handleChange,
  errors,
  classifiedType,
  classified_id,
}) => {
  const { data, error, isSuccess } = useGetCountriesSelectedQuery({});

  const {
    data: catPData,
    error: catPError,
    isSuccess: careerPSuccess,
  } = useGetPcategoriesDropDownQuery({
    category_id: classified_id,
  });
  const {
    data: careerLevelData,
    error: careerLevelError,
    isSuccess: careerLevelSuccess,
  } = useGetcategoriesDropDownQuery({
    idName: classifiedType === "jobs" ? "career_level" : "property_for_rent",
    category_id: classified_id,
  });
  const {
    data: propertiesFeaturesData,
    error: propertiesFeaturesError,
    isSuccess: propertiesFeaturesSuccess,
  } = useGetcategoriesDropDownQuery({
    idName: "properities",
    category_id: classified_id,
  });
  const {
    data: dataDiscrict,
    error: errorDistrict,
    isSuccess: successDistrict,
  } = useGetDistrictsQuery({
    country_id: formData.country_id || "KW",
    searchText: "",
  });

  const {
    data: dataArea,
    error: errorArea,
    isSuccess: successArea,
  } = useGetAreaDistrictsQuery(
    {
      district_id: formData.district_id,
      searchText: "",
    },
    {
      skip: !formData.district_id, // Skip query if district_id is not available
    }
  );

  return (
    <>
      {error && error.data.error && (
        <span className={styles.error}>{error.data.error}</span>
      )}

      {errorDistrict && errorDistrict?.data.error && (
        <span className={styles.error}>{errorDistrict?.data.error}</span>
      )}

      {errorArea && errorArea?.data.error && (
        <span className={styles.error}>{errorArea?.data.error}</span>
      )}

      {careerLevelError && careerLevelError?.data.error && (
        <span className={styles.error}>{careerLevelError?.data.error}</span>
      )}

      {catPError && catPError?.data.error && (
        <span className={styles.error}>{catPError?.data.error}</span>
      )}
      {classifiedType === "property" && (
        <div className={styles.field}>
          <label htmlFor="leaseType">Select Lease Type</label>
          <select
            id="leaseType"
            name="leaseType"
            value={formData.leaseType}
            onChange={handleChange}
          >
            <option value="">Select Lease Type</option>
            {catPData &&
              catPData.map((catP) => {
                return <option value={catP._id}>{catP.sub_category_en}</option>;
              })}
          </select>
          {errors.leaseType && (
            <span className={styles.error}>{errors.leaseType}</span>
          )}
        </div>
      )}

      {classifiedType === "property" && (
        <div className={styles.field}>
          <label
            htmlFor={classifiedType === "jobs" ? "careerLevel" : "buildingType"}
          >
            {classifiedType === "jobs" ? "career level" : "lease type"}
          </label>
          <select
            id={classifiedType === "jobs" ? "careerLevel" : "buildingType"}
            name={classifiedType === "jobs" ? "careerLevel" : "buildingType"}
            value={formData.area}
            onChange={handleChange}
          >
            <option value="">
              {classifiedType === "jobs"
                ? "select career level"
                : "Select lease type"}
            </option>
            {careerLevelSuccess &&
              careerLevelData.map((career) => {
                return <option value={career._id}>{career.value_en}</option>;
              })}
          </select>

          {errors.buildingType && classifiedType === "property" && (
            <span className={styles.error}>{errors.buildingType}</span>
          )}
          {errors.careerLevel && classifiedType === "jobs" && (
            <span className={styles.error}>{errors.careerLevel}</span>
          )}
        </div>
      )}

      <div className={styles.field}>
        <label htmlFor="country">Country</label>
        <select
          id="country"
          name="country_id"
          value={formData.country}
          defaultValue={"KW"}
          onChange={handleChange}
        >
          {isSuccess &&
            data.map((country) => {
              return (
                <option value={country._id} key={country._id}>
                  {country.country_english}
                </option>
              );
            })}
          s{/* Add more options as needed */}
        </select>
        {errors.country && (
          <span className={styles.error}>{errors.country}</span>
        )}
      </div>

      <div className={styles.field}>
        <label htmlFor="district">District</label>
        <select
          id="district"
          name="district_id"
          value={formData.district}
          onChange={handleChange}
        >
          <option value="">Select District</option>
          {successDistrict &&
            dataDiscrict.map((district) => {
              return (
                <option value={district._id}>{district.district_en}</option>
              );
            })}
        </select>
        {errors.district && (
          <span className={styles.error}>{errors.district}</span>
        )}
      </div>

      <div className={styles.field}>
        <label htmlFor="area">Area</label>
        <select
          id="area"
          name="area_id"
          value={formData.area}
          onChange={handleChange}
          disabled={successArea ? false : true}
        >
          <option value="">select area</option>
          {successArea &&
            successDistrict &&
            dataArea.map((area) => {
              console.log(area);
              return <option value={area._id}>{area.area_en}</option>;
            })}
        </select>
        {errors.area && <span className={styles.error}>{errors.area}</span>}
      </div>

      <div className={styles.field}>
        <label htmlFor="address">Other Address</label>
        <input
          type="text"
          id="address"
          name="address"
          value={formData.address}
          onChange={handleChange}
        />
        {errors.address && (
          <span className={styles.error}>{errors.address}</span>
        )}
      </div>

      {classifiedType === "property" && (
        <>
          {/* <div className={styles.field}>
            <label htmlFor="block">Block</label>
            <input
              type="text"
              id="block"
              name="block"
              value={formData.block}
              onChange={handleChange}
            />
            {errors.block && (
              <span className={styles.error}>{errors.block}</span>
            )}
          </div> */}

          <div className={styles.field}>
            <label htmlFor="street">Street</label>
            <input
              type="text"
              id="street"
              name="street"
              value={formData.street}
              onChange={handleChange}
            />
            {errors.street && (
              <span className={styles.error}>{errors.street}</span>
            )}
          </div>

          <div className={styles.field}>
            <label htmlFor="buildingNo">Building No</label>
            <input
              type="text"
              id="buildingNo"
              name="buildingNo"
              value={formData.buildingNo}
              onChange={handleChange}
            />
            {errors.buildingNo && (
              <span className={styles.error}>{errors.buildingNo}</span>
            )}
          </div>

          <div className={styles.field}>
            <label htmlFor="floor">Floor</label>
            <input
              type="text"
              id="floor"
              name="floor"
              value={formData.floor}
              onChange={handleChange}
            />
            {errors.floor && (
              <span className={styles.error}>{errors.floor}</span>
            )}
          </div>

          <div className={styles.field}>
            <label htmlFor="flatNo">Flat No</label>
            <input
              type="text"
              id="flatNo"
              name="flatNo"
              value={formData.flatNo}
              onChange={handleChange}
            />
            {errors.flatNo && (
              <span className={styles.error}>{errors.flatNo}</span>
            )}
          </div>

          <div className={styles.field}>
            <label htmlFor="blockNo">Block No</label>
            <input
              type="text"
              id="blockNo"
              name="blockNo"
              value={formData.blockNo}
              onChange={handleChange}
            />
            {errors.blockNo && (
              <span className={styles.error}>{errors.blockNo}</span>
            )}
          </div>

          <div className={styles.field}>
            <label htmlFor="rooms">Rooms</label>
            <input
              type="text"
              id="rooms"
              name="rooms"
              value={formData.rooms}
              onChange={handleChange}
            />
            {errors.rooms && (
              <span className={styles.error}>{errors.rooms}</span>
            )}
          </div>

          <div className={styles.field}>
            <label htmlFor="livingRooms">Living Rooms</label>
            <input
              type="text"
              id="livingRooms"
              name="livingRooms"
              value={formData.livingRooms}
              onChange={handleChange}
            />
            {errors.livingRooms && (
              <span className={styles.error}>{errors.livingRooms}</span>
            )}
          </div>

          <div className={styles.field}>
            <label htmlFor="bathrooms">Bathrooms</label>
            <input
              type="text"
              id="bathrooms"
              name="bathrooms"
              value={formData.bathrooms}
              onChange={handleChange}
            />
            {errors.bathrooms && (
              <span className={styles.error}>{errors.bathrooms}</span>
            )}
          </div>

          <div className={styles.field}>
            <label htmlFor="buildingAge">Building Age</label>
            <input
              type="text"
              id="buildingAge"
              name="buildingAge"
              value={formData.buildingAge}
              onChange={handleChange}
            />
            {errors.buildingAge && (
              <span className={styles.error}>{errors.buildingAge}</span>
            )}
          </div>
        </>
      )}

      <div className={styles.field}>
        <label htmlFor="title">Title</label>
        <input
          type="text"
          id="title"
          name="title"
          value={formData.title}
          onChange={handleChange}
        />
        {errors.title && <span className={styles.error}>{errors.title}</span>}
      </div>

      <div className={styles.field}>
        <label htmlFor="mobile">Mobile</label>
        <input
          type="text"
          id="mobile"
          name="mobile"
          value={formData.mobile}
          onChange={handleChange}
        />
        {errors.mobile && <span className={styles.error}>{errors.mobile}</span>}
      </div>

      <div className={styles.field}>
        <label htmlFor="price">Price</label>
        <input
          type="text"
          id="price"
          name="price"
          value={formData.price}
          onChange={handleChange}
        />
        {errors.price && <span className={styles.error}>{errors.price}</span>}
      </div>
      {classifiedType === "property" && (
        <div className={styles.fieldFull}>
          <div>
            <label>
              <input
                type="checkbox"
                name="bikeParking"
                checked={formData.bikeParking}
                onChange={handleChange}
              />
              <span style={{ marginLeft: "5px" }}>Bike Parking</span>
            </label>

            <label>
              <input
                type="checkbox"
                name="carParking"
                checked={formData.carParking}
                onChange={handleChange}
                style={{ marginLeft: "5px" }}
              />
              <span style={{ marginLeft: "5px" }}>Car Parking</span>
            </label>
          </div>

          {errors.bikeParking && (
            <span className={styles.error}>{errors.bikeParking}</span>
          )}
          {errors.carParking && (
            <span className={styles.error}>{errors.carParking}</span>
          )}
        </div>
      )}

      {classifiedType === "jobs" && (
        <div className={styles.field}>
          <label htmlFor="careerLevel">career level</label>
          <select
            id="careerLevel"
            name="careerLevel"
            value={formData.area}
            onChange={handleChange}
          >
            <option value="">Select Career level</option>
            {careerLevelSuccess &&
              careerLevelData.map((career) => {
                return <option value={career._id}>{career.value_en}</option>;
              })}
          </select>
          {errors.careerLevel && (
            <span className={styles.error}>{errors.careerLevel}</span>
          )}
        </div>
      )}

      {classifiedType === "buy & sell" && (
        <div className={styles.fieldFull}>
          <label htmlFor="oldItem">
            <input
              type="radio"
              id="oldItem"
              name="isItOldNotNew"
              value="old"
              checked={formData.isItOldNotNew === "old"}
              onChange={handleChange}
            />
            Old
          </label>

          <label htmlFor="newItem">
            <input
              type="radio"
              id="newItem"
              name="isItOldNotNew"
              value="new"
              checked={formData.isItOldNotNew === "new"}
              onChange={handleChange}
            />
            New
          </label>

          {errors.isItOldNotNew && (
            <span className={styles.error}>{errors.isItOldNotNew}</span>
          )}
        </div>
      )}

      <div className={styles.fieldFull}>
        <label htmlFor="description">Description</label>
        <textarea
          id="description"
          name="description"
          value={formData.description}
          onChange={handleChange}
        ></textarea>
      </div>
    </>
  );
};

export default AddClassiedPostCommonFields;
