import React, { useState } from 'react'

import { useSendNotificationMutation } from '../../redux/api/notificationApi'
import style from './notification.module.css'

const Notification = () => {
  const [title, setTittle] = useState('')
  const [description, setDescription] = useState('')
  const [loading, setLoading] = useState(false)
  const [whichType, setWhichType] = useState('')
  const [sendNotification] = useSendNotificationMutation()
  const onSaveHandler = async () => {
    try {
      if (!title) {
        alert('title is required')
        return
      }
      if (!description) {
        alert('description is required')
        return
      }

      if (!whichType) {
        alert('to whom you send is required')
        return
      }

      const res = await sendNotification({
        title,
        description,
      })

      if (res.error) {
        if (res.error.status === 400) {
          alert(res.error.data.error)
        }
      }
      if (res.data) {
        setDescription('')
        setTittle('')
        alert('notificatin sent')
      }
    } catch (err) {
      alert('something went wrong')
    }
    setLoading(false)
  }

  return (
    <div className='root-top-margin'>
      <div className={style.notificationWrapper}>
        <p>Send Notification</p>
        <div className={style.nameTextsWrapper}>
          <div className={style.textLable}>Titile</div>
          <div>
            <input
              value={title}
              type='text'
              className={style.nameTexts}
              onChange={(e) => {
                setTittle(e.target.value)
              }}
              disabled={loading ? true : false}
            />
          </div>
        </div>

        <div className={style.nameTextsWrapper}>
          <div className={style.textLable}>Description</div>
          <div>
            <input
              value={description}
              type='text'
              className={style.nameTexts}
              onChange={(e) => {
                setDescription(e.target.value)
              }}
              disabled={loading ? true : false}
            />
          </div>
        </div>

        <div className='mt-2 mb-2'>To Whom to send</div>
        <div>
          <input
            type='radio'
            onChange={(e) => {
              setWhichType('isforall')
            }}
            id='isforall'
            className='mr-1'
            name='options'
          />
          <label for='isforall'>To All</label>
        </div>

        <div>
          <input
            type='radio'
            onChange={(e) => {
              setWhichType('inperson')
            }}
            id='inPerson'
            name='options'
            className='mr-1'
          />
          <label for='inPerson'>To Personal emails</label>
        </div>

        <div className={style.buttonWrapper}>
          <button className='btn btn-success mt-3 ' onClick={onSaveHandler}>
            Send
          </button>
        </div>
      </div>
    </div>
  )
}

export default Notification
