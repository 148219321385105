import { configureStore } from '@reduxjs/toolkit'
import { userApi } from './api/userApi'
import { uploadApi } from './api/uploadApi'
import { classifiedApi } from './api/classifiedApi'
import { regionApi } from './api/regionApi'
import { bannerApi } from './api/bannerApi'
import { postApi } from './api/postApi'
import { storiesApi } from './api/storiesApi'
import { supportApi } from './api/supportApi'
import { notificationApi } from './api/notificationApi'
import { authApi } from './api/authApi'

import user from './slices/UserSlice'

const store = configureStore({
  reducer: {
    user: user,
    [userApi.reducerPath]: userApi.reducer,
    [uploadApi.reducerPath]: uploadApi.reducer,
    [classifiedApi.reducerPath]: classifiedApi.reducer,
    [regionApi.reducerPath]: regionApi.reducer,
    [bannerApi.reducerPath]: bannerApi.reducer,
    [postApi.reducerPath]: postApi.reducer,
    [storiesApi.reducerPath]: storiesApi.reducer,
    [supportApi.reducerPath]: supportApi.reducer,
    [notificationApi.reducerPath]: notificationApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(userApi.middleware)
      .concat(uploadApi.middleware)
      .concat(classifiedApi.middleware)
      .concat(regionApi.middleware)
      .concat(bannerApi.middleware)
      .concat(postApi.middleware)
      .concat(storiesApi.middleware)
      .concat(supportApi.middleware)
      .concat(notificationApi.middleware)
      .concat(authApi.middleware),
})

export default store
