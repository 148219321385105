import React, { useState } from 'react'
import { BsEyeFill } from 'react-icons/bs'
import ListTables from '../../components/tables/ListTables'
import { useGetTicketsQuery } from '../../redux/api/supportApi'
import ViewTicket from '../../components/tickets/ViewTicket'

const ContactFrom = ({}) => {
  const [page, setPage] = useState(1)
  const [viewTicket, setViewTicket] = useState('')

  // Update the state with the new array

  const dataRemap = (data) => {
    return data.tickets.map(filter)
  }

  const filter = (obj) => {
    return {
      _id: obj._id,
      id: obj._id,
      userName: obj.ticket_raised_user.name,
      title: obj.title,
      status: obj.status,
      date: new Date(obj.createdAt).toLocaleDateString('de-DE'),
      action: (
        <BsEyeFill
          className='cursor-pointer'
          onClick={() => {
            setViewTicket(obj)
          }}
        />
      ),
    }
  }

  return (
    <div className='root-top-margin'>
      {!viewTicket && (
        <ListTables
          tableTitle='Tickets'
          tableHeders={[
            'Ticket NO',
            'User',
            'Title',
            'status',
            'date',
            'Action',
          ]}
          dataRemap={dataRemap}
          useGetListHook={useGetTicketsQuery}
          setPage={setPage}
          page={page}
          maxCountInaPage={10}
        />
      )}
      {viewTicket && (
        <ViewTicket
          ticketRaiser={{
            ID: viewTicket._id,
            userName: viewTicket.ticket_raised_user.name,
          }}
          setViewTicket={setViewTicket}
        />
      )}
    </div>
  )
}

export default ContactFrom
