import React, { useState } from 'react'
import { BsTrash, BsPencilFill } from 'react-icons/bs'
import { useNavigate } from 'react-router-dom'
import { IoIosAddCircleOutline } from 'react-icons/io'
import { useGetClassifiedsSubCategoryQuery,useDeleteSubClassifiedCategoryMutation } from '../../redux/api/classifiedApi'
import AddSubClassifiedCategory from '../../components/classifieds/AddSubClassifiedCategory'
import { removeLocalStorageAndNavigate } from '../../utils/helperFunctions'
import ListTables from '../../components/tables/ListTables'
import styles from './classifieds.module.css'

const SubCategories = ({ setSubCategories, category,addSubclassified,setAddSubClassified }) => {
  const navigate = useNavigate()
  const [deleteSubClassifiedCategory] = useDeleteSubClassifiedCategoryMutation()
  const [subClassifiedCat, setSubClassifiedCat] = useState({ _id: '' })
  const [loading, setLoading] = useState(false)
  const dataRemap = (data) => {
    return data.map(filter)
  }

  const filter = (obj) => {
    return {
      _id: obj._id,
      ParentCategory: obj.category_id.category_en,
      category_id: obj.category_id._id,
      name_en: obj.sub_category_en,
      name_ar: obj.sub_category_ar,
      icon: obj.sub_category_image ? (
        <img
          src={obj.sub_category_image}
          style={{ height: '100px', width: '100px' }}
        />
      ) : (
        ''
      ),
    }
  }

  // const deleteHandler = async (list) => {
  //   if (window.confirm('Do you want to delete the sub classified category ?')) {
  //     await deleteClassifiedCategory({
  //       category_id: list._id,
  //     })
  //   }
  // }

  const deleteHandler = async (list) => {
    setLoading(true)
    if (window.confirm('Do you want to delete the sub classified category ?')) {
    const res = await deleteSubClassifiedCategory({
      sub_category_id:list._id
    })

    if (res.error) {
      if (res.error.status === 400) {
        alert(res.error.data.error)
      }
      if (res.error.status === 401) {
        removeLocalStorageAndNavigate(navigate)
      }
    }
    if (res.data) {
    }
    setLoading(false)
  }
  }

 

  const editClassified = (list) => {

    console.log('list',list)
    setAddSubClassified(true)
    setSubClassifiedCat({
      _id: list._id,
      category_id: list.category_id,
      sub_category_en: list.name_en,
      sub_category_ar: list.name_ar,
      image: list.icon,
    })
  }

  return (
    <div>
      {!addSubclassified && (
        <>
        <div style={{justifyContent:"space-between",display:"flex"}}>
          <button
            className='btn btn-danger'
            onClick={() => {
              setSubCategories(false)
            }}
          >
            Back
          </button>

          <button
            className='btn btn-danger'
            onClick={() => {
              setAddSubClassified(true)
            }}
          >
            ADD
          </button>
          </div>

          {loading && (
          <div>
            <span className='loader-spiner'></span>
            <span className='text-primary'>updating...</span>
          </div>
        )}

          <div className={styles.subCategoryWrapper}>
            <ListTables
              search={true}
              tableTitle='Lists of sub categories'
              tableHeders={[
                'Parent Category',
                'category_id',
                'Name EN',
                'Name AR',
                'Icon',
                'Actions',
              ]}
              paramAdditional={{
                category_id: category._id,
              }}
              useGetListHook={useGetClassifiedsSubCategoryQuery}
              dataRemap={dataRemap}
              actions={[
                { icon: <BsTrash />, handler: deleteHandler },
                { icon: <BsPencilFill />, handler: editClassified },
              ]}
            />
          </div>
        </>
      )}

      {addSubclassified && (
        <AddSubClassifiedCategory
          setAddSubClassified={setAddSubClassified}
          subClassifiedCat={subClassifiedCat}
          setSubClassifiedCat={setSubClassifiedCat}
          setSubCategories={setSubCategories}
        />
      )}
    </div>
  )
}

export default SubCategories
