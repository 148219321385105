import React from "react";
import { useNavigate } from "react-router-dom";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { removeLocalStorageAndNavigate } from "../../utils/helperFunctions";
import { useGetStoryQuery } from "../../redux/api/storiesApi";
import image from "../../images/user2.jpg";
import style from "./story.module.css";

const ViewStory = ({ ID, setViewPost }) => {
  const navigate = useNavigate();
  const { data, error, isLoading, isSuccess } = useGetStoryQuery({
    story_id: ID,
  });

  if (error) {
    if (error.status === 401) {
      removeLocalStorageAndNavigate(navigate);
    } else {
      alert(error.data.error);
    }
  }

  console.log(data);

  return (
    <div>
      {isLoading && (
        <div className="flex-item-center">
          <span className="loader-spiner"></span>
        </div>
      )}
      {isSuccess && (
        <div className="flex-item-space-btw">
          <div className={style.boxWrapper}>
            <div>
              <AiOutlineArrowLeft
                size={25}
                className="cursor-pointer"
                onClick={() => setViewPost("")}
              />
              <div className="flex-item-space-btw">
                <div>
                  <div className="pt-2 pb-2">
                    <span>Posted User:</span>
                    <span className="ml-1">
                      {data.user_id ? data.user_id.name : "not found"}
                    </span>
                  </div>

                  <div className="pt-2 pb-2">
                    <span>Status:</span>
                    <span className="ml-1">
                      {data.active ? "active" : "not active"}
                    </span>
                  </div>

                  <div className="pt-2 pb-2">
                    <span>Created:</span>
                    <span className="ml-1">
                      {new Date(data.createdAt).toLocaleDateString("de-DE")},
                    </span>
                    <span>
                      {new Date(data.createdAt).toLocaleTimeString("en-US")}
                    </span>
                  </div>
                </div>
                <div
                  className="flex-item-center p-1"
                  style={{ height: "400px" }}
                >
                  {data.story_type === "image" ? (
                    data.story_url ? (
                      <img
                        // className={style.imgThumb}
                        src={data.story_url}
                        alt={`${data._id} Story`}
                        onError={(e) => {
                          e.target.onError = null;
                          e.target.src = image; // Fallback image when the main URL fails
                        }}
                      />
                    ) : null
                  ) : data.story_type === "video" && data.story_url ? (
                    <video controls>
                      <source src={data.story_url} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          {data.user_id && (
            <div className={style.imageWrapper}>
              <div className="flex-item-center">
                <img
                  src={data.user_id.photo}
                  alt={data.user_id.name}
                  onError={(e) => {
                    e.target.onError = null;
                    e.target.src = image;
                  }}
                  className={style.imgRound}
                />
              </div>
              <div className="flex-item-center">
                <h4>{data.user_id.name}</h4>
              </div>
              <hr />
              <div className="text-center">
                <h3>Description</h3>
                <div>{data.description}</div>
              </div>
            </div>
          )}
        </div>
      )}
      <div className="btn btn-danger mt-2" onClick={() => setViewPost("")}>
        Back
      </div>
    </div>
  );
};

export default ViewStory;
