import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const postApi = createApi({
  reducerPath: 'postApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_backendurl,
    prepareHeaders: (headers) => {
      if (typeof Storage !== 'undefined') {
        const user = JSON.parse(localStorage.getItem('RaselListing'))
        if (user?.token) headers.set('Authorization', `Bearer ${user?.token}`)
      }
      return headers
    },
  }),
  tagTypes: ['Posts'],
  endpoints: (builder) => ({
    getPosts: builder.query({
      query: ({ page, maxCountInaPage, searchText, user_id }) => ({
        url: `api/post/get-all-posts?page=${page}&itemsPerPage=${maxCountInaPage}${
          searchText ? `&textSearch=${searchText}` : ''
        }${user_id ? `&user_id=${user_id}` : ''}`,
      }),
      providesTags: ['Posts'],
    }),
    getPost: builder.query({
      query: ({ post_id }) => ({
        url: `api/post/get-post-by-admin?post_id=${post_id}`,
      }),
      providesTags: ['aPost'],
    }),

    getComments: builder.query({
      query: ({ post_id }) => ({
        url: `api/post/getcomments?page=1&itemsPerPage=4&post_id=${post_id}`,
      }),
    }),
    getReportedPost: builder.query({
      query: ({ page, maxCountInaPage, post_id }) => ({
        url: `api/post/get-reported-post?post_id=${post_id}&page=${page}&itemsPerPage=${maxCountInaPage}`,
      }),
    }),
    activeDisActivePost: builder.mutation({
      query(data) {
        return {
          url: `api/post/hide-post`,
          method: 'POST',
          body: data,
        }
      },
      invalidatesTags: ['Posts', 'aPost'],
    }),
  }),
})

export const {
  useGetPostsQuery,
  useGetPostQuery,
  useGetCommentsQuery,
  useGetReportedPostQuery,
  useActiveDisActivePostMutation,
} = postApi
