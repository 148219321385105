import React from "react";
import { useNavigate } from "react-router-dom";
import { IoMdCheckboxOutline, IoIosSquareOutline } from "react-icons/io";
import ListTables from "../../components/tables/ListTables";
import { removeLocalStorageAndNavigate } from "../../utils/helperFunctions";
import {
  useGetCountriesQuery,
  useUpdateSelectCountryMutation,
} from "../../redux/api/regionApi";

const Countries = () => {
  const navigate = useNavigate();

  const [updateSelectCountry, { error }] = useUpdateSelectCountryMutation();

  if (error) {
    if (error.status === 401) {
      removeLocalStorageAndNavigate(navigate);
    }
  }

  const dataRemap = (data) => {
    return data.map(filter);
  };

  const filter = (obj) => {
    return {
      _id: obj._id,
      id: obj._id,
      name_en: obj.country_english,
      isSelected: obj.isSelected,
    };
  };

  const selectCountry = async (country) => {
    console.log(country);
    const data = { country_id: country._id, isSelected: !country.isSelected };
    await updateSelectCountry(data);
  };

  return (
    <div className="root-top-margin">
      <ListTables
        search={true}
        tableTitle="Countries"
        tableHeders={["id", "Name EN", "isSelected", "Actions"]}
        useGetListHook={useGetCountriesQuery}
        dataRemap={dataRemap}
        actions={[
          {
            icon: <IoIosSquareOutline size={20} />,
            selectedIcon: <IoMdCheckboxOutline size={18} />,
            handler: selectCountry,
          },
        ]}
        errordata={
          error &&
          (error.data.error ? error.data.error : "some thing went wrong")
        }
      />
    </div>
  );
};

export default Countries;
