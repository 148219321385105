import React, { useState } from "react";
import { Nav } from "react-bootstrap";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import { TbLayoutDashboard, TbMessageReport } from "react-icons/tb";
import { PiFlagBanner } from "react-icons/pi";
import { Link } from "react-router-dom";
import { HiUserGroup } from "react-icons/hi";
import { AiOutlineFileSearch } from "react-icons/ai";
import { BsFiles, BsGeoFill } from "react-icons/bs";
import { useNavigate, useLocation } from "react-router-dom";
import { removeLocalStorageAndNavigate } from "../../utils/helperFunctions";
import Error from "../Error";
import style from "./navbar.module.css";
import Logo from "../../images/OrangeLogo-box.png";
import { useGetClassifiedsCategoryQuery } from "../../redux/api/classifiedApi";
import Classifieds from "../../pages/classifieds/Classifieds";

const NavbarMenu = ({ setClassifiedPost, setNavOpen, navOpen }) => {
  const navigate = useNavigate();
  const Query = new URLSearchParams(useLocation().search);
  const [expand, setExpand] = useState(false);
  const [expandRegion, setExpandRegion] = useState(false);
  const [expandPost, setExpandPost] = useState(false);
  const [expandSetting, setExpandSetting] = useState(false);
  const [activeClassified, setActiveClassified] = useState(null);
  const { data, error, isLoading, isSuccess } = useGetClassifiedsCategoryQuery(
    {}
  );

  if (error && error.status === 401) {
    removeLocalStorageAndNavigate(navigate);
  }

  const user = JSON.parse(localStorage.getItem("RaselListing"));

  console.log(user);

  if (user.role === "admin") {
    return (
      <div
        className={`d-md-block ${style.sidebar}`}
        activeKey="/rm-admin/dashboard"
        id="sideNavmenu"
        style={{ position: "fixed", overflow: "auto" }}
      >
        <Nav.Item
          onClick={() => {
            if (navOpen) {
              setNavOpen(false);
              document.getElementById("sideNavmenu").style.display = "none";
            }
          }}
          href="/rm-admin/dashboard"
        >
          <div className={`${style.logoNavbar}`}>
            <img
              src={Logo}
              style={{ height: "50px", width: "50px" }}
              alt="logo rasel"
            />
            <span className="ml-1">RASEL MEDIA</span>
          </div>
        </Nav.Item>
        <Nav.Item
          onClick={() => {
            if (navOpen) {
              setNavOpen(false);
              document.getElementById("sideNavmenu").style.display = "none";
            }
          }}
          className={`${style.navItem}`}
        >
          <Link to="/rm-admin/dashboard" className={`${style.navLink}`}>
            <span>
              <TbLayoutDashboard className={`${style.navIcons}`} size={20} />
            </span>
            <span className="ml-1">Dashboad</span>
          </Link>
        </Nav.Item>
        <Nav.Item
          onClick={() => {
            if (navOpen) {
              setNavOpen(false);
              document.getElementById("sideNavmenu").style.display = "none";
            }
          }}
          className={`${style.navItem}`}
        >
          <Link to="/rm-admin/userslist" className={`${style.navLink}`}>
            <span>
              <HiUserGroup className={`${style.navIcons}`} size={20} />
            </span>
            <span className="ml-1">Users</span>
          </Link>
        </Nav.Item>

        <Nav.Item className={`${style.navItem}`}>
          <div
            className={`flex-item-space-btw cursor-pointer ${style.dropdown} ${
              expandPost && `${style.dropdownSelected}`
            }`}
            onClick={() => setExpandPost(!expandPost)}
          >
            <div>
              <span>
                <BsFiles className={`${style.navIcons}`} size={20} />
              </span>
              <span className="ml-1">Posts</span>
            </div>
            <div className="mr-1">
              {!expandPost ? <IoMdArrowDropdown /> : <IoMdArrowDropup />}
            </div>
          </div>

          {expandPost && (
            <div className="ml-2">
              <Nav.Item
                onClick={() => {
                  if (navOpen) {
                    setNavOpen(false);
                    document.getElementById("sideNavmenu").style.display =
                      "none";
                  }
                }}
              >
                <Link to="/rm-admin/posts" className={`${style.navLink} mt-3`}>
                  <span className="ml-1">Posts</span>
                </Link>
              </Nav.Item>
              <Nav.Item
                onClick={() => {
                  if (navOpen) {
                    setNavOpen(false);
                    document.getElementById("sideNavmenu").style.display =
                      "none";
                  }
                }}
              >
                <Link
                  to="/rm-admin/stories"
                  className={`${style.navLink} mt-3`}
                >
                  <span className="ml-1">Stories</span>
                </Link>
              </Nav.Item>
            </div>
          )}
        </Nav.Item>
        <Nav.Item className={`${style.navItem}`}>
          <div
            className={`flex-item-space-btw cursor-pointer ${style.dropdown} ${
              expand && `${style.dropdownSelected}`
            }`}
            onClick={() => setExpand(!expand)}
          >
            {error && (
              <Error
                error={
                  error.data.error ? error.data.error : "some thing went wrong"
                }
              />
            )}
            {isLoading && (
              <div className={`${style.loginwrapperloader} mt-2`}>
                <span className="loader"></span>
              </div>
            )}
            <div>
              <span>
                <AiOutlineFileSearch
                  className={`${style.navIcons}`}
                  size={20}
                />
              </span>
              <span className="ml-1">Classifieds</span>
            </div>
            <div className="mr-1">
              {!expand ? <IoMdArrowDropdown /> : <IoMdArrowDropup />}
            </div>
          </div>
          <div className="ml-2">
            {expand && (
              <>
                <Nav.Item
                  onClick={() => {
                    if (navOpen) {
                      setNavOpen(false);
                      document.getElementById("sideNavmenu").style.display =
                        "none";
                    }
                  }}
                >
                  <Link
                    to="/rm-admin/classifieds"
                    className={`${style.navLink} mt-3`}
                  >
                    <span className="ml-1">ADD Classified</span>
                  </Link>
                </Nav.Item>

                {isSuccess &&
                  data.map((classified) => {
                    return (
                      <Nav.Item
                        onClick={() => {
                          if (navOpen) {
                            setNavOpen(false);
                            document.getElementById(
                              "sideNavmenu"
                            ).style.display = "none";
                            setActiveClassified(classified._id); // Set active classified
                          }
                        }}
                        key={classified._id}
                        className={
                          classified._id === Query.get("id")
                            ? style.activeNavItem
                            : ""
                        }
                      >
                        <Link
                          to={`/rm-admin/classifieds/posts?id=${
                            classified._id
                          }&classified=${classified.category_en.replace(
                            "&",
                            "-"
                          )}`}
                          onClick={() => setClassifiedPost("")}
                          className={`${style.navLink} mt-3`}
                        >
                          <span className="ml-1">
                            Classified {classified.category_en}
                          </span>
                        </Link>
                      </Nav.Item>
                    );
                  })}
              </>
            )}
          </div>
        </Nav.Item>
        <Nav.Item className={`${style.navItem}`}>
          <div
            className={`flex-item-space-btw cursor-pointer ${style.dropdown} ${
              expandRegion && `${style.dropdownSelected}`
            }`}
            onClick={() => setExpandRegion(!expandRegion)}
          >
            <div>
              <span>
                <BsGeoFill className={`${style.navIcons}`} size={20} />
              </span>
              <span className="ml-1">Region</span>
            </div>
            <div className="mr-1">
              {!expandRegion ? <IoMdArrowDropdown /> : <IoMdArrowDropup />}
            </div>
          </div>
          {expandRegion && (
            <div className="ml-2">
              <Nav.Item
                onClick={() => {
                  if (navOpen) {
                    setNavOpen(false);
                    document.getElementById("sideNavmenu").style.display =
                      "none";
                  }
                }}
              >
                <Link
                  to="/rm-admin/countries"
                  className={`${style.navLink} mt-3`}
                >
                  <span className="ml-1">Countries</span>
                </Link>
              </Nav.Item>

              <Nav.Item
                onClick={() => {
                  if (navOpen) {
                    setNavOpen(false);
                    document.getElementById("sideNavmenu").style.display =
                      "none";
                  }
                }}
              >
                <Link
                  to="/rm-admin/districts"
                  className={`${style.navLink} mt-3`}
                >
                  <span className="ml-1">Districts</span>
                </Link>
              </Nav.Item>
              <Nav.Item
                onClick={() => {
                  if (navOpen) {
                    setNavOpen(false);
                    document.getElementById("sideNavmenu").style.display =
                      "none";
                  }
                }}
              >
                <Link to="/rm-admin/areas" className={`${style.navLink} mt-3`}>
                  <span className="ml-1">Areas</span>
                </Link>
              </Nav.Item>
            </div>
          )}
        </Nav.Item>

        <Nav.Item
          onClick={() => {
            if (navOpen) {
              setNavOpen(false);
              document.getElementById("sideNavmenu").style.display = "none";
            }
          }}
          className={`${style.navItem}`}
        >
          <Link to="/rm-admin/banners" className={`${style.navLink}`}>
            <span>
              <PiFlagBanner className={`${style.navIcons}`} size={20} />
            </span>
            <span className="ml-1">Banners</span>
          </Link>
        </Nav.Item>
        <Nav.Item className={`${style.navItem}`}>
          <div
            className={`flex-item-space-btw cursor-pointer ${style.dropdown} ${
              expandSetting && `${style.dropdownSelected}`
            }`}
            onClick={() => setExpandSetting(!expandSetting)}
          >
            <div>
              <span>
                <BsFiles className={`${style.navIcons}`} size={20} />
              </span>
              <span className="ml-1">Settings</span>
            </div>
            <div className="mr-1">
              {!expandSetting ? <IoMdArrowDropdown /> : <IoMdArrowDropup />}
            </div>
          </div>

          {expandSetting && (
            <div className="ml-2">
              <Nav.Item
                onClick={() => {
                  if (navOpen) {
                    setNavOpen(false);
                    document.getElementById("sideNavmenu").style.display =
                      "none";
                  }
                }}
              >
                <Link
                  to="/rm-admin/settings"
                  className={`${style.navLink} mt-3`}
                >
                  <span className="ml-1">App Setting</span>
                </Link>
              </Nav.Item>
              <Nav.Item
                onClick={() => {
                  if (navOpen) {
                    setNavOpen(false);
                    document.getElementById("sideNavmenu").style.display =
                      "none";
                  }
                }}
              >
                <Link
                  to="/rm-admin/change-password"
                  className={`${style.navLink} mt-3`}
                >
                  <span className="ml-1">Change Password</span>
                </Link>
              </Nav.Item>
            </div>
          )}
        </Nav.Item>
        <Nav.Item
          onClick={() => {
            if (navOpen) {
              setNavOpen(false);
              document.getElementById("sideNavmenu").style.display = "none";
            }
          }}
          className={`${style.navItem}`}
        >
          <Link to="/rm-admin/reported-users" className={`${style.navLink}`}>
            <span>
              <TbMessageReport className={`${style.navIcons}`} size={20} />
            </span>
            <span className="ml-1">Reported-user</span>
          </Link>
        </Nav.Item>
        <Nav.Item
          onClick={() => {
            if (navOpen) {
              setNavOpen(false);
              document.getElementById("sideNavmenu").style.display = "none";
            }
          }}
          className={`${style.navItem}`}
        >
          <Link to="/rm-admin/contactform" className={`${style.navLink}`}>
            <span>
              <TbMessageReport className={`${style.navIcons}`} size={20} />
            </span>
            <span className="ml-1">Contact Froms</span>
          </Link>
        </Nav.Item>
        <Nav.Item
          onClick={() => {
            if (navOpen) {
              setNavOpen(false);
              document.getElementById("sideNavmenu").style.display = "none";
            }
          }}
          className={`${style.navItem}`}
        >
          <Link to="/rm-admin/notifications" className={`${style.navLink}`}>
            <span>
              <TbMessageReport className={`${style.navIcons}`} size={20} />
            </span>
            <span className="ml-1">Notification</span>
          </Link>
        </Nav.Item>
        <Nav.Item
          onClick={() => {
            if (navOpen) {
              setNavOpen(false);
              document.getElementById("sideNavmenu").style.display = "none";
            }
          }}
          className={`${style.navItem}`}
        >
          <Link to="/rm-admin/tickets" className={`${style.navLink}`}>
            <span>
              <TbMessageReport className={`${style.navIcons}`} size={20} />
            </span>
            <span className="ml-1">Tickets</span>
          </Link>
        </Nav.Item>
      </div>
    );
  }

  if (user.role === "dataentry") {
    return (
      <div
        className={`d-md-block ${style.sidebar}`}
        activeKey="/rm-admin/dashboard"
        id="sideNavmenu"
        style={{ position: "fixed", overflow: "auto" }}
      >
        <Nav.Item
          onClick={() => {
            if (navOpen) {
              setNavOpen(false);
              document.getElementById("sideNavmenu").style.display = "none";
            }
          }}
          href="/rm-admin/dashboard"
        >
          <div className={`${style.logoNavbar}`}>
            <img
              src={Logo}
              style={{ height: "50px", width: "50px" }}
              alt="logo rasel"
            />
            <span className="ml-1">RASEL MEDIA</span>
          </div>
        </Nav.Item>
        <Nav.Item
          onClick={() => {
            if (navOpen) {
              setNavOpen(false);
              document.getElementById("sideNavmenu").style.display = "none";
            }
          }}
          className={`${style.navItem}`}
        >
          <Link to="/rm-admin/dashboard" className={`${style.navLink}`}>
            <span>
              <TbLayoutDashboard className={`${style.navIcons}`} size={20} />
            </span>
            <span className="ml-1">Dashboad</span>
          </Link>
        </Nav.Item>

        <Nav.Item className={`${style.navItem}`}>
          <div
            className={`flex-item-space-btw cursor-pointer ${style.dropdown} ${
              expand && `${style.dropdownSelected}`
            }`}
            onClick={() => setExpand(!expand)}
          >
            {error && (
              <Error
                error={
                  error.data.error ? error.data.error : "some thing went wrong"
                }
              />
            )}
            {isLoading && (
              <div className={`${style.loginwrapperloader} mt-2`}>
                <span className="loader"></span>
              </div>
            )}
            <div>
              <span>
                <AiOutlineFileSearch
                  className={`${style.navIcons}`}
                  size={20}
                />
              </span>
              <span className="ml-1">Classifieds</span>
            </div>
            <div className="mr-1">
              {!expand ? <IoMdArrowDropdown /> : <IoMdArrowDropup />}
            </div>
          </div>
          <div className="ml-2">
            {expand && (
              <>
                {isSuccess &&
                  data.map((classified) => {
                    return (
                      <Nav.Item
                        onClick={() => {
                          if (navOpen) {
                            setNavOpen(false);
                            document.getElementById(
                              "sideNavmenu"
                            ).style.display = "none";
                            setActiveClassified(classified._id); // Set active classified
                          }
                        }}
                        key={classified._id}
                        className={
                          classified._id === Query.get("id")
                            ? style.activeNavItem
                            : ""
                        }
                      >
                        <Link
                          to={`/rm-admin/classifieds/posts?id=${
                            classified._id
                          }&classified=${classified.category_en.replace(
                            "&",
                            "-"
                          )}`}
                          onClick={() => setClassifiedPost("")}
                          className={`${style.navLink} mt-3`}
                        >
                          <span className="ml-1">
                            Classified {classified.category_en}
                          </span>
                        </Link>
                      </Nav.Item>
                    );
                  })}
              </>
            )}
          </div>
        </Nav.Item>
      </div>
    );
  }
};

export default NavbarMenu;
