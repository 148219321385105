import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_backendurl,
    prepareHeaders: (headers) => {
      if (typeof Storage !== 'undefined') {
        const user = JSON.parse(localStorage.getItem('RaselListing'))
        if (user?.token) headers.set('Authorization', `Bearer ${user?.token}`)
      }
      return headers
    },
  }),
  tagTypes: ['User', 'Users'],
  endpoints: (builder) => ({
    getUsers: builder.query({
      query: ({ page, maxCountInaPage, searchText }) => ({
        url: `api/get-users?page=${page}&maxCountInaPage=${maxCountInaPage}${
          searchText ? `&textSearch=${searchText}` : ''
        }`,
      }),
      providesTags: ['Users'],
    }),

    getReportedUsers: builder.query({
      query: ({ page, maxCountInaPage }) => ({
        url: `api/user/get-reported-user?page=${page}&itemsPerPage=${maxCountInaPage}`,
      }),
    }),

    getUserByID: builder.query({
      query: ({ user_id }) => ({
        url: `api/user/get-user-by-id?user_id=${user_id}`,
      }),
      providesTags: ['User'],
    }),
    blockUser: builder.mutation({
      query(data) {
        return {
          url: `api/admin/blockuserbyadmin`,
          method: 'POST',
          body: data,
        }
      },
      invalidatesTags: ['User'],
    }),
    deleteUser: builder.mutation({
      query(data) {
        return {
          url: `api/admin/deleteuserbyadmin`,
          method: 'POST',
          body: data,
        }
      },
      invalidatesTags: ['User'],
    }),
    userStatus: builder.mutation({
      query(data) {
        return {
          url: `api/admin/userstatusbyadmin`,
          method: 'POST',
          body: data,
        }
      },
      invalidatesTags: ['Users','User'],
    }),

    totalCounts: builder.mutation({
      query(data) {
        return {
          url: `api/admin/totalcounts`,
          method: 'POST',
          body: data,
        }
      },
    }),
  }),
})

export const {
  useGetUsersQuery,
  useGetUserByIDQuery,
  useBlockUserMutation,
  useDeleteUserMutation,
  useTotalCountsMutation,
  useGetReportedUsersQuery,
  useUserStatusMutation
} = userApi
