import React, { useState, useEffect } from "react";
import { IoMdEyeOff, IoMdEye } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import style from "./login.module.css";
import logo from "../../images/OrangeLogo-box.png";
import { getDataPostWithOutHeader } from "../../utils/api";
import {
  ifUserInLocalStorageNavigate,
  setLocalStorage,
} from "../../utils/helperFunctions";

const Login = () => {
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    ifUserInLocalStorageNavigate(navigate);
  }, [navigate]);

  const loginSubmitHandler = async () => {
    setLoading(true);
    if (username === "") {
      setError("Enter eamil or password");
      return;
    }

    if (password === "") {
      setError("Enter password");
      return;
    }

    try {
      const url = `${process.env.REACT_APP_backendurl}/api/login`;
      const params = {
        email: username,
        password,
      };

      const res = await getDataPostWithOutHeader(url, params);
      setError("");
      setLoading(false);

      console.log("data", res);

      if (res.data.role === "admin" || res.data.role === "dataentry") {
        setLocalStorage(res.data);
        return navigate("/rm-admin/dashboard");
      } else {
        alert("Your're not a admin or dataentry");
        return navigate("/rm-admin");
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
      setError(err.message);
    }
  };
  return (
    <div
      className={`${style.loginBoxWrapper} flex-item-center flex-item-align-center`}
    >
      <div style={{ marginBottom: "2rem" }}>
        <img src={logo} className={`${style.imagLogo}`} alt="rasel logo" />
      </div>
      {loading && (
        <div className={`${style.loginwrapperloader} mt-2`}>
          <span className="loader"></span>
        </div>
      )}
      <div className={`${style.loginwrapper} `}>
        {error && <div className="color-danger overflow-hidden">{error}</div>}
        <div className="mb-2 display-flex">
          <input
            className={`${style.credentials} pr-1 pb-1 pt-1 `}
            type="text"
            placeholder="Username"
            value={username}
            onChange={(e) => setUserName(e.target.value)}
          />
        </div>
        <div
          className="mb-2 display-flex"
          style={{ borderBottom: "1px solid black" }}
        >
          <input
            type={`${showPassword ? "text" : "password"}`}
            className={`${style.credentials} pr-1 pb-1 pt-1 `}
            style={{ borderBottom: "0px solid black" }}
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />

          {!showPassword && (
            <IoMdEyeOff
              size={20}
              style={{ cursor: "pointer", marginTop: "10px" }}
              onClick={() => setShowPassword(true)}
            />
          )}
          {showPassword && (
            <IoMdEye
              size={20}
              style={{ cursor: "pointer", marginTop: "10px" }}
              onClick={() => setShowPassword(false)}
            />
          )}
        </div>

        <div className="flex-item-center">
          <button className={style.loginbtn} onClick={loginSubmitHandler}>
            Login
          </button>
        </div>
      </div>
    </div>
  );
};

export default Login;
