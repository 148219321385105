import axios from 'axios'

export const getRequestedData = async (url, cache) => {
  try {
    return await axios.get(url, cache)
  } catch (error) {
    console.log(error)
    throw new Error(error.response.data.error)
  }
}

export const getRequestedDataWithHeader = async (url, authtoken) => {
  try {
    return await axios.get(url, {
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
  } catch (error) {
    throw new Error(error.response.data.error)
  }
}

export const getDataPostWithOutHeader = async (url, data) => {
  try {
    return await axios.post(url, data)
  } catch (error) {
    console.log(error)
    throw new Error(error.response.data.error)
  }
}

export const getDataPostWithHeader = async (url, data, authtoken) => {
  try {
    return await axios.post(url, data, {
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
  } catch (error) {
    console.log(error)
    throw new Error(error.response.data.error)
  }
}
