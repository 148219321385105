import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { BsTrash, BsEyeFill } from "react-icons/bs";
import ListTables from "../tables/ListTables";
import {
  useDeleteUserMutation,
  useGetUsersQuery,
} from "../../redux/api/userApi";
import { removeLocalStorageAndNavigate } from "../../utils/helperFunctions";

import User from "./User";

const Users = () => {
  const navigate = useNavigate();
  const [viewUser, setViewUser] = useState("");
  const [page, setPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [deleteUser, { error }] = useDeleteUserMutation();

  if (error) {
    if (error.status === 401) {
      removeLocalStorageAndNavigate(navigate);
    }
  }

  const dataRemap = (data) => {
    return data.users.map(filter);
  };

  const filter = (obj) => {
    return {
      _id: obj._id,
      name: obj.name,
      phone: obj.phone,
      email: obj.email,
      country: obj.country.country_english,
      active: obj.active,
    };
  };

  const viewUserHandler = async (user) => {
    setViewUser(user._id);
  };

  const deleteHandler = async (user) => {
    if (window.confirm("Do you want to delete the user ?")) {
      const data = { user_id_to_delete: user._id };
      await deleteUser(data);
    }
    //
  };

  return (
    <div className="root-top-margin">
      {!viewUser && (
        <ListTables
          search={true}
          tableTitle="User List Table"
          tableHeders={[
            "Name",
            "Mobile",
            "Email",
            "Country",
            "Status",
            "Actions",
          ]}
          dataRemap={dataRemap}
          useGetListHook={useGetUsersQuery}
          actions={[
            { icon: <BsEyeFill />, handler: viewUserHandler },
            { icon: <BsTrash />, handler: deleteHandler },
          ]}
          setPage={setPage}
          page={page}
          errordata={
            error &&
            (error.data.error ? error.data.error : "some thing went wrong")
          }
        />
      )}
      {viewUser && <User ID={viewUser} setViewUser={setViewUser} />}
    </div>
  );
};

export default Users;
