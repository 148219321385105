import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { removeLocalStorageAndNavigate } from '../../utils/helperFunctions'
import {
  useGetATicketQuery,
  useUpdateTicketMutation,
} from '../../redux/api/supportApi'
import Error from '../Error'
import styles from './tickets.module.css'

const ViewTicket = ({ ticketRaiser, setViewTicket }) => {
  const navigate = useNavigate()
  const [status, setStatus] = useState('open')
  const [replay, setReplay] = useState('')
  const { data, error, isLoading, isSuccess } = useGetATicketQuery({
    ticket_id: ticketRaiser.ID,
  })
  const [updateTicket] = useUpdateTicketMutation()

  if (error === 401) {
    if (error.status === 401) {
      removeLocalStorageAndNavigate(navigate)
    }
  }

  useEffect(() => {
    if (data) {
      console.log(data)
      setReplay(data.tickets.length > 1 ? data.tickets[1].message : '')
      setStatus(data.tickets[0].tiket_manager_id.status)
    }
  }, [data])

  const updateTicketManger = async (state) => {
    const res = await updateTicket({
      ticket_id: ticketRaiser.ID,
      replay: replay,
      status: state,
    })

    if (res.error) {
      if (res.error.status === 400) {
        alert(res.error.data.error)
      }
      if (res.error.status !== 400) {
        alert(res.error.data.error)
      }

      if (res.error.status === 401) {
        removeLocalStorageAndNavigate(navigate)
      }
    }
    if (res.data) {
      setReplay(res.data.replay ? res.data.replay : '')
      setStatus(res.data.status)
      setViewTicket('')
    }
  }

  return (
    <div className={`${styles.boxWrapper}`}>
      {isLoading && (
        <div className='flex-item-center'>
          <span className='loader-spiner'></span>
        </div>
      )}

      {error && (
        <Error
          error={error.data.error ? error.data.error : 'some thing went wrong'}
        />
      )}

      {isSuccess && (
        <div>
          <div className={styles.labelWrapper}>Ticket No</div>
          <div className={styles.labelAnswer}>{data.tickets[0]._id}</div>
          <div className={styles.labelWrapper}>Title</div>
          <div className={styles.labelAnswer}>
            {data.tickets[0].tiket_manager_id.title}
          </div>
          <div className={styles.labelWrapper}>Description</div>
          <div className={styles.labelAnswer}>{data.tickets[0].message}</div>
          <div className={styles.labelWrapper}>Name</div>
          <div className={styles.labelAnswer}>{ticketRaiser.userName}</div>
          <div className={styles.labelWrapper}>Replay</div>
          <textarea
            className={styles.replayText}
            autofocus
            value={replay}
            onChange={(e) => {
              setReplay(e.target.value)
            }}
          />
        </div>
      )}

      <div className={styles.buttonWrapper}>
        <button
          onClick={() => updateTicketManger(status)}
          className='btn btn-info'
        >
          Update Replay
        </button>
        {status === 'open' && (
          <button
            onClick={() => updateTicketManger('closed')}
            className='btn btn-warning'
          >
            close ticket
          </button>
        )}
        {status === 'closed' && (
          <button
            onClick={() => updateTicketManger('open')}
            className='btn btn-success'
          >
            open ticket
          </button>
        )}
        <button className='btn btn-danger' onClick={() => setViewTicket('')}>
          back
        </button>
      </div>
    </div>
  )
}

export default ViewTicket
