import React from 'react'
import homeimg from '../../images/homeimg.png'
import './home.css'

const Home = () => {
  return (
    <div style={{ background: '#fff' }}>
      <div className='home-img-wrapper'>
        <img src={homeimg} alt='home' className='w-100'></img>
      </div>
    </div>
  )
}

export default Home
