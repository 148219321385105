import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { IoIosArrowRoundBack } from "react-icons/io";

import * as XLSX from "xlsx";
import {
  useAddClassifiedPostMutation,
  useGetPcategoriesDropDownQuery,
  useGetcategoriesDropDownQuery,
  useAddValuesToCategoryMutation,
  useAddValuesBulkToCategoryMutation,
} from "../../redux/api/classifiedApi";

import styles from "../addClassifiedPost/Form.module.css";
import style from "../classifieds/classified.module.css";

const AddValuesToDropDowns = ({ classifiedID, classifiedType }) => {
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState("");
  const [addDropDwon, setAddDropDown] = useState(false);
  const [loading, setLoading] = useState(false);
  const [nameEn, setNameEn] = useState("");
  const [nameAr, setNameAr] = useState("");
  const [bulkData, setBulkData] = useState([]);
  const {
    data: modelData,
    error: modelError,
    isSuccess: modelSuccess,
  } = useGetcategoriesDropDownQuery({
    idName: classifiedType === "automotive" ? "model" : "career_level",
    category_id: classifiedID,
  });

  const [AddValuesToCategory] = useAddValuesToCategoryMutation();
  const [AddValuesBulkToCategory] = useAddValuesBulkToCategoryMutation();

  const addValuesDropDownCatgeory = async () => {
    if (!nameEn) {
      alert("name EN is required");
      return;
    }
    if (!nameAr) {
      alert("name AR is required");
      return;
    }

    if (modelSuccess) {
      setLoading(true);
      const res = await AddValuesToCategory({
        value_en: nameEn,
        value_ar: nameAr,
        sub_category_id: modelData[0].sub_category_id,
        category_id: classifiedID,
      });

      if (res.error) {
        if (res.error.status === 401) {
          setLoading(false);
          alert("Not authorized, login again");
          window.location.href = "/rm-admin";
          return;
        }
        setLoading(false);
        alert(res.error.data.error);
      }
      if (res.data) {
        setSuccessMessage("Data added successfully!");
        setNameAr("");
        setNameEn("");
        setAddDropDown(false);
        setTimeout(() => {
          setSuccessMessage("");
        }, 3000);
      }
      setLoading(false);
    } else {
      alert("model not loaded");
    }
  };

  const handleFileUpload = (e) => {
    setLoading(true);
    const file = e.target.files[0];

    const fileSizeInMB = file.size / (1024 * 1024); // Convert bytes to MB
    if (fileSizeInMB >= 50) {
      alert("File size should be less than 50MB");
      setLoading(false);
      return; // Stop further execution if the file is too large
    }
    const reader = new FileReader();
    reader.onload = (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];
      const json = XLSX.utils.sheet_to_json(worksheet);

      // Update bulkData state with the converted JSON
      const BulkData = json.map((row) => ({
        value_en: row["value_en"], // Map to the expected fields
        value_ar: row["value_ar"],
        identity_name:
          classifiedType === "automotive" ? "model" : "career_level",
        sub_category_id: modelData[0].sub_category_id,
        category_id: classifiedID,
      }));

      setBulkData(BulkData);

      insertBulkData(BulkData);
    };

    reader.readAsArrayBuffer(file);
  };

  const insertBulkData = async (BulkData) => {
    if (BulkData.length > 0) {
      const res = await AddValuesBulkToCategory({
        subCategoriesValuesDropDown: BulkData,
      });
      console.log(BulkData);
      if (res.error) {
        setLoading(false);
        setBulkData([]);
        if (res.error.status === 401) {
          setLoading(false);
          alert("Not authorized, login again");
          window.location.href = "/rm-admin";
          return;
        }
        if (res.error.status === "FETCH_ERROR") {
          setLoading(false);
          alert("Network error");
          return;
        }
        alert(res.error.data.error);
        console.log(res.error);
      }

      if (res.data) {
        setSuccessMessage("Data added successfully!");
        setBulkData([]);
        setAddDropDown(false);
        setLoading(false);
        // Show success message

        // Hide the success message after 3 seconds
        setTimeout(() => {
          setSuccessMessage("");
        }, 3000);
      }
    } else {
      alert("No bulk data to insert");
      setLoading(false);
    }
  };
  return (
    <div className="root-top-margin" style={{ background: "white" }}>
      {!addDropDwon && (
        <IoIosArrowRoundBack
          size={40}
          style={{ cursor: "pointer" }}
          onClick={() => navigate(-1)}
        />
      )}
      <div
        style={{
          background: "white",
          width: "100%",
          margin: "auto",
          padding: "10px",
        }}
      >
        {!addDropDwon && modelSuccess && (
          <>
            {modelError && modelError?.data.error && (
              <span className={styles.error}>{modelError?.data.error}</span>
            )}

            {successMessage && (
              <span className={styles.successm}>{successMessage}</span>
            )}

            <div className={styles.field}>
              <label htmlFor="model">DropDOwn</label>
              <select id="model" name="model">
                <option value="">Select</option>
                {modelSuccess &&
                  modelData.map((model) => (
                    <option value={model._id} key={model._id}>
                      {model.value_en}
                    </option>
                  ))}
              </select>

              {errors.model && (
                <span className={styles.error}>{errors.model}</span>
              )}

              {loading && (
                <div>
                  <span className="loader-spiner"></span>
                  <span className="text-primary">
                    uploding bulk may take time please wait until it uploads
                  </span>
                </div>
              )}
              <div className="display-flex">
                <div
                  className="btn btn-primary m-1"
                  style={{ width: "200px" }}
                  onClick={() => {
                    setAddDropDown(true);
                  }}
                >
                  Add One
                </div>
                <div style={{ width: "200px" }}>
                  <input
                    type="file"
                    accept=".xlsx, .xls"
                    onChange={handleFileUpload}
                    style={{ display: "none" }}
                    id="bulkUpload"
                  />
                  <label
                    htmlFor="bulkUpload"
                    style={{ cursor: "pointer" }}
                    className="btn btn-primary m-1"
                  >
                    Insert bulk
                  </label>
                </div>
              </div>
              {/* {bulkData.length > 0 && (
              <div className="mt-2">
                <button className="btn btn-success" onClick={insertBulkData}>
                  Upload Bulk Data
                </button>
              </div>
            )} */}
            </div>
          </>
        )}

        {addDropDwon && (
          <>
            <div className={style.classifiedHeaderWrapper}>
              Add Model for drop down
            </div>
            <div className={style.addCategoryWrapper}>
              {loading && (
                <div>
                  <span className="loader-spiner"></span>
                  <span className="text-primary">...adding post</span>
                </div>
              )}
              <div className={style.nameTextsWrapper}>
                <div className={style.textLable}>Name(EN)</div>
                <div>
                  <input
                    value={nameEn}
                    type="text"
                    className={style.nameTexts}
                    onChange={(e) => {
                      setNameEn(e.target.value);
                    }}
                    disabled={loading ? true : false}
                  />
                </div>
              </div>
              <div className={style.nameTextsWrapper}>
                <div className={style.textLable}>Name(AR)</div>
                <div>
                  <input
                    type="text"
                    value={nameAr}
                    className={style.nameTexts}
                    onChange={(e) => {
                      setNameAr(e.target.value);
                    }}
                    disabled={loading ? true : false}
                  />
                </div>
              </div>

              <div className={style.buttonWrapper}>
                <button
                  className="btn btn-success"
                  onClick={addValuesDropDownCatgeory}
                >
                  Save
                </button>
                <button
                  className="btn btn-danger"
                  onClick={() => {
                    setAddDropDown(false);
                  }}
                >
                  Back
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default AddValuesToDropDowns;
