import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { removeLocalStorageAndNavigate } from '../../utils/helperFunctions'
import ListTables from '../../components/tables/ListTables'
import {
  useGetContactFormsQuery,
  useUpdateContactFormMutation,
} from '../../redux/api/supportApi'

const ContactFrom = ({}) => {
  const navigate = useNavigate()
  const [page, setPage] = useState(1)
  const [updateContactForm] = useUpdateContactFormMutation()

  // if (error) {
  //   if (error.status === 401) {
  //     removeLocalStorageAndNavigate(navigate)
  //   }
  // }

  const dataRemap = (data) => {
    return data.contactforms.map(filter)
  }

  const filter = (obj) => {
    return {
      _id: obj._id,
      id: obj._id,
      userName: obj.user_id.name,
      email: obj.user_id.email,
      message: obj.message,
      date: new Date(obj.createdAt).toLocaleDateString('de-DE'),
      Action: (
        <select
          defaultValue={obj.action}
          onChange={(e) => onUpdateHandler(e.target.value, obj._id)}
        >
          <option value='pending'>pending</option>
          <option value='completed'>completed</option>
        </select>
      ),
    }
  }

  const onUpdateHandler = async (value, ID) => {
    const res = await updateContactForm({
      contactform_id: ID,
      action: value,
    })

    if (res.error) {
      if (res.error.status === 400) {
        alert(res.error.data.error)
      }
      if (res.error.status !== 400) {
        alert(res.error.data.error)
      }
      if (res.error.status !== 401) {
        removeLocalStorageAndNavigate(navigate)
      }
    }
    if (res.data) {
      console.log(res.data)
    }
  }
  return (
    <div className='root-top-margin'>
      <ListTables
        tableTitle='Contactform Table'
        tableHeders={[
          'ID',
          'Name',
          'Email',
          'Message',
          'created date',
          'Action',
        ]}
        dataRemap={dataRemap}
        useGetListHook={useGetContactFormsQuery}
        setPage={setPage}
        page={page}
        maxCountInaPage={10}
        // errordata={
        //   error &&
        //   (error.data.error ? error.data.error : 'some thing went wrong')
        // }
      />
    </div>
  )
}

export default ContactFrom
